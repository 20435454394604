import * as axios from 'axios'
import config from '@/config'
import shortId from '@/services/short-id'
import { getJwtToken } from '@/services/auth'

function upload(file, missionId) {
  return new Promise(function(fulfilled, rejected) {
    const url = process.env.VUE_APP_EXEC_API + config.s3SignedUrl
    axios.defaults.headers.common['Authorization'] = getJwtToken()
    const shortFileName = function(str) {
      return (str.split('.').pop()).toLowerCase()
    }
    var id = shortId()
    let fname = `${missionId}/${id}.${shortFileName(file.name)}`
    var tags = 'missionId=' + missionId
    return axios({ method: 'post', url: url, data: { name: fname, type: file.type, tagging: tags } })
      .then(x => {
        var options = { headers: { 'Content-Type': file.type, 'x-amz-tagging': tags } }
        delete axios.defaults.headers.common['Authorization']
        return axios.put(x.data.uploadURL, file, options).then(() => {
            fulfilled(fname)
          })
          .catch((err) => { console.log(err) });
      })
      .catch((err) => { console.log(err); return rejected(err) })
  })
}

export { upload }