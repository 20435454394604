export default {
  region: 'us-west-2',
  UserPoolId: 'us-west-2:05f2f95d-a797-45c3-aeaa-285bbd1baf07',
  IdentityPoolId: 'us-west-2_ztKjvKpC6',
  //IdentityPoolId: 'us-west-2:05f2f95d-a797-45c3-aeaa-285bbd1baf07',
  //UserPoolId: 'us-west-2_ztKjvKpC6',
  ClientId: '2e04b776234u7k262vskjfleil',
  s3SignedUrl: '/upload-node',
  MAX_PRO_USER_LEVEL: 100,
  MAX_EDITOR_LEVEL: 20,
  MAX_ADMIN_LEVEL: 10,
  INVESTOR_ORG_LEVEL: 120
}