const nestedProperty = require('nested-property')
const MAX_PPAE = 1000
const MAX_MAX_ENTRIES = 1000
export default function validate(ctx, prop) {
  if (!Object.prototype.hasOwnProperty.call(ctx.mission, prop)) {
    ctx.isValid[prop] = false
    return
  }
  if (prop === 'title') {
    const regex = new RegExp('^[^?@#$%^&*]{5,100}$')
    const result = ctx.mission[prop].match(regex)
    ctx.isValid[prop] = result !== null
    return
  }
  if (prop === 'geoArea') {
    const regex = new RegExp('^[^!?@#$%^&*]{5,50}$')
    const result = ctx.mission[prop].match(regex)
    ctx.isValid[prop] = result !== null
    return
  }
  if (prop === 'description') {
    const regex = new RegExp('^[\\w\\s\\S]{10,1000}$')
    const result = ctx.mission[prop].match(regex)
    ctx.isValid[prop] = result !== null
    return
  }
  if (prop === 'ppae') {
    const val = parseInt(ctx.mission[prop] || 0)
    const min = parseInt(nestedProperty.get(ctx, 'template.minPoints')) || 1 // don't allow missions that don't offer points
    const condition1 = val >= min
    const condition2 = val <= MAX_PPAE
    ctx.isValid[prop] = condition1 && condition2
    return
  }
  if (prop === 'maxEntries') {
    const val = parseInt(ctx.mission[prop] || 0)
    const condition1 = val >= 1
    const condition2 = val <= MAX_MAX_ENTRIES
    ctx.isValid[prop] = condition1 && condition2
    return
  }
  ctx.isValid[prop] = false
}