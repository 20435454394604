<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth text-center">
          <a @click="$router.push({name: 'HomePage', params: {lang: lang}})" class="navbar-brand" style="margin-top: 2rem; display: block;">Scout Action</a>
        </div>
      </div>
    </div>
    <div class="container">
      <div v-if="!signedUp" class="columns">
        <div class="column col-9 col-mx-auto maxPageWidth">
          <div class="section-spacer"></div>
          <div class="label label-error mb-2" v-if="error != null">{{ error }}</div>
          <form @submit.prevent="signUp">
            <div class="columns">
              <div class="column col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-mx-auto">
                <h6 class="text-center" style="width: 100%; margin-bottom: 1rem;">{{$t('registerTitle')}}</h6>
                <!-- <div class="form-group" :class="[usernameIsFocused ? (usernameIsOk ? 'has-success' : 'has-error') : '']">
                  <input id="username" type="text" class="form-input" v-model="username" :placeholder="$t('Username')" required @focus="usernameIsFocused=true">
                  <p class="form-input-hint">{{$t('registerFormUsername')}}</p>
                </div> -->
                <div class="form-group" :class="[emailIsFocused ? (emailIsOk ? 'has-success' : 'has-error') : '']">
                  <input id="email" type="text" class="form-input" v-model="email" placeholder="Email" required @focus="emailIsFocused=true">
                  <p class="form-input-hint">{{$t('registerFormEmail')}}</p>
                </div>
                <div class="form-group" :class="[pwdIsFocused ? (passIsOk ? 'has-success' : 'has-error') : '']">
                  <input id="password" type="password" class="form-input" :placeholder="$t('Password')" v-model="pass" required @focus="pwdIsFocused=true">
                  <p class="form-input-hint">{{$t('registerFormPassword')}}</p>
                </div>
              </div>
            </div>
            <div class="p-centered" style="max-width: 300px; margin-top: 1rem; text-align: center;">
              <div class="center-align">
                <div class="form-group" style="margin-bottom: 1rem; height: 2rem;">
                  <label class="form-checkbox float-left" style="margin-left: 10%;">
                    <input id="mailing" type="checkbox" v-model="mailing">
                    <i class="form-icon"></i> <small>{{$t('SendEmailUpdates')}}</small>
                  </label>
                </div>
                <button class="btn btn-primary btn-large col-12" :class="[isWorking ? 'disabled loading' : '']"><span v-show="!isWorking">{{$t('Register')}}</span></button>
                <div class="mt-2"></div>
                <p>
                  <small>
                    <router-link :to="({name: 'HomePage', params: {lang: lang}})">{{$t('Home')}}</router-link>
                    &middot;
                    <router-link :to="({name: 'LoginPage', params: {lang: lang}})">{{$t('LogIn')}}</router-link>
                  </small>
                </p>
              </div>
              <div class="section-spacer"></div>
            </div>
          </form>
        </div>
      </div>
      <div v-else class="columns">
        <div class="column col-9 col-mx-auto maxPageWidth p-centered">
          <br><br>
          <p v-html="message"></p>
          <p>{{email}}</p>
        </div>
        <div class="section-spacer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n-lite'
import postAsync from '@/services/post-async'
export default {
  name: 'RegisterPage',
  title: function() {
    return `Scout Action :: ${this.$t('Register')}`
  },
  setup() {
    const route = useRoute()
    const lang = route.params ? route.params.lang : 'en'
    return { lang }
  },
  data: function() {
    return {
      i18n: useI18n(),
      currentLocale: 'en',
      // username: '',
      pass: '',
      email: '',
      error: null,
      loggedIn: false,
      isWorking: false,
      signedUp: false,
      message: '',
      mailing: true,
      pwdIsFocused: false,
      // usernameIsFocused: false,
      emailIsFocused: false
    }
  },
  beforeCreate: function() {
    document.body.className = 'public-zone'
  },
  mounted: function() {
    const { current } = this.i18n
    let lang = localStorage.getItem('lang') || 'en'
    lang = ['es', 'en'].indexOf(lang) === -1 ? 'en' : lang
    this.currentLocale = current || lang
  },
  computed: {
    passIsOk: function() {
      return this.pass.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
    },
    /*
    usernameIsOk: function() {
      return this.username.match(/^[a-zA-Z0-9]{5,12}$/)
    },
    */
    emailIsOk: function() {
      return this.email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,14}$/)
    }
  },
  methods: {
    async signUp() {
      const self = this
      this.isWorking = true
      if (!this.passIsOk /* || !this.usernameIsOk */ || !this.emailIsOk) {
        alert('Por favor corrige los errores')
        this.isWorking = false
        return
      }
      const payload = {
        // username: this.username,
        email: this.email,
        password: this.pass,
        lang: this.currentLocale,
        mailing: this.mailing
      }
      await postAsync(payload, 'user/signup').catch(error => {
        // console.log(error)
        const localError = {
          'Password did not conform with policy: Password not long enough': 'La contraseña no se ajusta a la política: la contraseña no es lo suficientemente larga',
          'Password did not conform with policy: Password must have lowercase characters': 'La contraseña no se ajusta a la política: la contraseña debe tener caracteres en minúsculas',
          'Password did not conform with policy: Password must have uppercase characters': 'La contraseña no se ajusta a la política: la contraseña debe tener caracteres en mayúsculas',
          'Password did not conform with policy: Password must have symbol characters': 'La contraseña no se ajusta a la política: la contraseña debe tener caracteres de símbolo',
          'User already exists': 'Ya existe un usario con ese nombre de usuario'
        }
        self.error = this.currentLocale === 'es' ? localError[error.message.trim()] : error.message
        self.isWorking = false
        return false
      })
      // console.log(response)
      // this.message = this.$t('CheckYourEmailToConfirm')
      // this.signedUp = true
      this.$router.replace('/successr')
    }
  }
}
</script>
<style>
.form-input-hint {
  font-size: 0.6rem !important;
  margin-bottom: 0.5rem !important;
}

p.benefit {
  line-height: 1rem !important;
  margin-bottom: 0.6rem !important;
}
</style>