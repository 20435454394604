<template>
  <div class="columns">
    <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
      <SiteNav :user="user" />
      <div class="card browse-card mb-2">
        <div class="card-body">
          <h4>{{$t('NewMissionSuccessTitle')}}</h4>
          <p>{{$t('NewMissionSuccessText')}}</p>
          <br>
          <p>
            <router-link :to="'/dash'" class="btn btn-link btn-sm btn-hover mr-2">&larr; {{$t('Home')}}</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav.vue'
export default {
  name: 'SuccessMissionPage',
  title: function() {
    return `Scout Action :: ${this.$t('NewMissionSuccessTitle')}`
  },
  components: {
    SiteNav
  },
  props: {
    user: Object
  },
  created() {
    document.body.className = 'auth-zone'
  }
}
</script>