<template>
  <div id="app">
    <div v-if="isLoading" class="label-loading p-fixed bg-dark"><span class="p-centered text-center">{{isLoading}}</span></div>
    <router-view :user="user" />
    <div v-if="showCookieMessage" class="cookieMessage text-center">
      <button v-on:click="hideMessage" class="btn btn-clear float-right" style="color: #fff !important;"></button>
      <small>{{$t('CookieMessage')}}</small>
    </div>
  </div>
</template>
<!-- <style src="./assets/styles-legacy.css"></style> -->
<style src="./assets/board.css"></style>
<style src="./assets/spectre.min.css"></style>
<style src="./assets/spectre-exp.min.css"></style>
<style src="./assets/spectre-icons.min.css"></style>
<script>
// https://gist.github.com/yajra/5f5551649b20c8f668aec48549ef5c1f
import * as axios from 'axios'
axios.interceptors.response.use(function(response) { return response; }, function(error) { if (401 === error.response.status) { window.location.reload(true); } else { return Promise.reject(error); } });
import emitter from '@/lib/general/emitter'
import { useState } from 'vue-gtag-next'
import config from '@/config'
import { getUser } from '@/services/auth'
const nestedProperty = require('nested-property')
import { setJwtToken, getJwtToken, tokenIsValid, setRefreshToken, setScoutId, setTeamId } from '@/services/auth'
import ReconnectingWebSocket from 'reconnecting-websocket'
let socket
export default {
  name: 'App',
  data: function() {
    return {
      user: {},
      isLoading: '',
      showCookieMessage: !localStorage.getItem('showed-msg')
    }
  },
  created: function() {
    emitter.on('loading', this.loadingFn)
    emitter.on('login', this.setLoginData)
    emitter.on('checkin', this.setCheckinData)
    emitter.on('logout', this.doLogout)
  },
  beforeUnmount() {
    emitter.off('loading')
    emitter.off('login')
    emitter.off('checkin')
    emitter.off('logout')
  },
  mounted: function() {
    this.enableTracking()
    const user = getUser()
    if (user) {
      this.user = user
      document.body.className = 'auth-zone'
    }
    this.connectWS()
  },
  methods: {
    connectWS() {
      const self = this
      // https://docs.stackery.io/docs/api/nodes/WebSocketApi
      // https://stackoverflow.com/questions/64440110/aws-cognito-and-websocket-api-using-lambda-authorizer
      // https://github.com/socketio/socket.io-client/issues/1370
      // https://stackoverflow.com/questions/23674199/why-is-there-no-same-origin-policy-for-websockets-why-can-i-connect-to-ws-loc
      // https://stackoverflow.com/questions/55785745/how-to-programmatically-connect-to-aws-websocket-api-gateway
      const token = getJwtToken()
      if (!socket && token) {
        socket = new ReconnectingWebSocket(process.env.VUE_APP_WS_API + `?Authorizer=${token}`)
        socket.addEventListener('open', function() {
          // console.log('socket open')
          // console.log(data)
        })
        socket.addEventListener('message', function(event) {
          // console.log(event.data)
          const json = JSON.parse(event.data) // event.data
          if (self.user.userId === json.userId) {
            emitter.emit('pointsUpdate', {
              points: json.points
            })
          }
        })
        socket.addEventListener('error', function() {
          // console.log('error', tokenIsValid(), error)
          if (!tokenIsValid() && socket) {
            socket.close()
            // socket.removeEventListener('open', fail)
            // socket.removeEventListener('message', fail)
            // socket.removeEventListener('error', fail)
            // socket.removeEventListener('close', fail)
            socket = null
          }
        })
        socket.addEventListener('close', function() {
          console.log('socket closed')
        })
      }
    },
    setLoginData(loginResponse) {
      if (!loginResponse) {
        return false
      }
      document.body.className = 'auth-zone'
      this.user.userId = loginResponse.data.id
      this.user.username = loginResponse.data.username
      this.user.scoutId = loginResponse.data.scoutId
      this.user.teamId = loginResponse.data.teamId
      this.user.email = loginResponse.data.email
      this.user.mailing = loginResponse.data.mailing
      this.user.iplevel = loginResponse.data.iplevel
      this.user.isEditor = this.user.iplevel <= config.MAX_EDITOR_LEVEL
      setJwtToken(loginResponse.data.idToken)
      setRefreshToken(loginResponse.data.refreshToken)
      if (nestedProperty.get(loginResponse, 'data.iplevel') <= config.MAX_EDITOR_LEVEL) {
        localStorage.setItem('sa-role', 'scout action team')
      }
      this.connectWS()
    },
    setCheckinData(checkinResponse) {
      setTeamId(checkinResponse.data.teamId)
      setScoutId(checkinResponse.data.scoutId)
      this.user.scoutId = checkinResponse.data.scoutId
      this.user.teamId = checkinResponse.data.teamId
    },
    hideMessage() {
      localStorage.setItem('showed-msg', true)
      this.showCookieMessage = false
    },
    enableTracking() {
      const { isEnabled } = useState()
      const enabled = (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'staging' && !localStorage.getItem('sa-role'))
      isEnabled.value = enabled
    },
    loadingFn: function(val = '') {
      const self = this
      const str = val.split('|')[0]
      const timeout = val.split('|')[1]
      this.isLoading = str === 'loading' ? self.$t('Loading') : str
      if (timeout && Number(timeout) > 0) {
        setTimeout(function() { self.loadingFn('') }, Number(timeout))
      }
    },
    doLogout() {
      document.body.className = 'public-zone'
      socket.close()
      socket = null
    }
  }
}
</script>
<style>
#app {
  overflow-x: hidden;
  /*padding: 0 15px;*/
  max-width: 1600px;
  margin: 0 auto;
}

#bottom-space {
  height: 2rem;
}

a {
  cursor: pointer;
  color: #407BFF !important;
}

a:visited {
  color: #407BFF;
}

a:active,
a:focus {
  outline: 0 !important;
  /*border: none !important;*/
  -moz-outline-style: none !important;
  box-shadow: none !important;
}

h2,
h3,
h4,
h5 {
  font-style: normal;
  color: #3B4351 !important;
}

h1 {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #3B4351 !important;
  font-size: 2.7rem !important;
  line-height: 2.6rem !important;
  letter-spacing: -0.1rem !important;
}

h2 {
  font-size: 1.8rem !important;
  line-height: 1.8rem !important;
  letter-spacing: -0.1rem !important;
  font-weight: 900 !important;
}

h3 {
  margin-bottom: 1rem;
  font-size: 1.6rem !important;
  letter-spacing: -0.05rem;
  font-weight: 800 !important;
}

h4 {
  margin-bottom: 1rem;
  font-size: 1.3rem !important;
  line-height: 1.3rem !important;
  letter-spacing: -0.05rem;
  font-weight: 800 !important;
}

h5 {
  font-size: 1.1rem !important;
  line-height: 1.1rem !important;
  letter-spacing: -0.03rem;
  font-weight: 800 !important;
}

h6 {
  font-size: 1rem !important;
  line-height: 1rem !important;
  letter-spacing: -0.03rem;
  font-weight: 600 !important;
}

p {
  line-height: 1.3rem;
  /*margin: 0 0 1rem;*/
  /*margin: 0;*/
}

@media only screen and (max-width: 600px) {
  h2 {
    /*font-size: 180% !important;*/
    font-size: 1.8rem !important;
    line-height: 1.7rem !important;
    letter-spacing: -0.05rem !important;
  }

  h3 {
    font-size: 150% !important;
  }

  h4 {
    font-size: 130% !important;
    margin-bottom: 0.7rem;
  }

  h5 {
    font-size: 103% !important;
  }

  h6 {
    font-size: 103% !important;
  }

  p {
    line-height: 1.3rem;
  }
}

.btn {
  color: #407BFF !important;
  border-color: #407BFF !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn:active {
  background-color: #FFFFFF !important;
}

.btn-primary {
  border-radius: .3rem !important;
}

.btn-secondary {
  border-radius: .3rem !important;
}

.btn-large {
  height: 2.5rem !important;
}

.btn-sm {
  border-radius: .2rem !important;
  border: 0.05rem solid #407BFF !important;
}

.btn-hover:hover {
  background-color: #FFFFFF !important;
}

.btn.btn-primary {
  color: #fff !important;
  background: #407BFF !important;
  border-color: #407BFF !important;
  /*border-color: #41a7f3 !important;*/
}

.form-label {
  font-weight: 600 !important;
}

/*.form-input:focus {
  border-color: #2699F0;
  box-shadow: 0 0 0 .1rem rgba(25, 151, 243, .2);
}*/

textarea {
  border-radius: .2rem !important;
  /*border: 0.05rem solid #407BFF !important;*/
  box-shadow: none !important;
}

input[type="text"]:focus,
input[type="checkbox"],
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none !important;
  box-shadow: 0 !important;
  border: 0 !important;
}

.form-switch input:checked+.form-icon {
  background: #407BFF !important;
  border-color: #407BFF !important;
}

.label-loading {
  min-width: 130px;
  /*max-width: 450px;*/
  padding: 0 10px;
  left: 50%;
  margin-left: -65px;
  font-size: 0.7rem;
  z-index: 10000;
  margin-top: 2px;
}

.loading-blue::after {
  border-bottom-color: rgb(0, 128, 255) !important;
  border-left-color: rgb(0, 128, 255) !important;
}

.public-zone {
  background-color: #fffefc;
}

.auth-zone {
  /*background-color: #fffefc;*/
  /*background-color: #f4f7f8;*/
  /*background-color: #fff;*/
  /*background-color: #f7f9fd;*/
  background-color: #f7f8f9;
}

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap'); */
@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.main-title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #3B4351 !important;
}

.section-spacer {
  height: 2rem;
}

.menu-item {
  margin-bottom: 5px;
}

.btn-expand {
  background: #FFFFFF !important;
  color: #407BFF !important;
  border: 1px solid #407BFF !important;
}

.btn-hide {
  background: #407BFF !important;
  color: #FFFFFF !important;
  border: 0 !important;
}

.form-checkbox input:checked+.form-icon {
  background: #407BFF !important;
  border-color: #407BFF !important;
}

.navbar-brand {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900 !important;
  color: #3B4351 !important;
  font-size: 1rem !important;
  letter-spacing: -0.04rem;
  text-decoration: none !important;
}

.navbar {
  padding: 0 0 0 5px;
  margin: 2rem 0 1rem 0;
  /*font-weight: 600;*/
}

.navbar-header {
  margin-top: 2px;
}

.footer {
  margin-bottom: 3rem;
}

.footer p {
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin: 0 0 0.3rem;
}

.footer a {
  color: #407BFF;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .footer p {
    font-size: 0.85rem;
    line-height: 0.95rem;
  }
}

.maxPageWidth {
  max-width: 728px;
}

.smallestIcon {
  width: 15px;
}

.smallIcon {
  width: 20px;
}

.acti {
  display: inline-block;
  margin-right: 8px;
}

.sa-tag {
  background: #FFFFFF !important;
  /*background: #407BFF !important;*/
  position: relative;
  display: inline-block;
  /*  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;*/
  padding: 3px 5px 3px 5px !important;
  border-radius: 2px !important;
  margin-right: 5px !important;
  color: #407BFF !important;
  line-height: 1;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: .6rem;
  border: 1px solid #407BFF !important;
}

img[lazy=error] {
  background: url('~@/assets/img/empty.png')
}

.warning {
  color: #e85600 !important;
  border-color: #e85600 !important;
}

.editor-action {
  color: #32b643 !important;
  border-color: #32b643 !important;
}

.btn-bold {
  background-color: #32b643 !important;
  color: #fff !important;
  border-color: #32b643 !important;
}

.btn-bold:active {
  color: #32b643 !important;
}

.dropbox {
  outline: 1px dashed #ccc !important;
  /* the dash box */
  outline-offset: -10px;
  background: #fefefe;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.dropbox.has-error {
  background: #fffaf7;
}

.dropbox.has-success {
  border-color: #32b643;
}

.dropbox:hover {
  background: #fbfbfb;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.dropzone {
  border: 1px solid #ddd !important;
  border-radius: 4px;
}

.dropzone.has-error {
  border-color: #e85600 !important;
}

.dropzone.has-success {
  border-color: #32b643 !important;
}

.field-area {
  margin-bottom: 2rem;
}


.progress:indeterminate {
  -webkit-animation: progress-indeterminate 1.5s linear infinite;
  animation: progress-indeterminate 1.5s linear infinite;
  background: #eef0f3 linear-gradient(to right, #407bff 30%, #eef0f3 30%) top left/150% 150% no-repeat;
}

.cookieMessage {
  background: rgba(64, 123, 255, 0.96);
  border-color: #407BFF;
  position: fixed;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  width: 300px;
  bottom: 5px;
  z-index: 1000;
  color: #fff;
  line-height: 0.8rem;
  padding: 0.5rem;
}

.content-area {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #E4E6E7;
  /* box-shadow: 0px 2px 5px rgba(0,0,0,0.4); */
}

.warningBox {
  border: 1px dashed #FFB700;
  background: #fffaf7;
  padding: 3px;
  font-size: 0.7rem;
  margin-bottom: 1rem;
}

.form-input-preview:focus {
  border: 1px solid #407BFF !important;
  box-shadow: 0 0 0 0.1rem rgb(64 123 255 / 100%) !important;
}
.right-side {
  padding: 1rem;
}
@media only screen and (min-width: 600px) {
  .right-side {
    padding: 3rem;
  }
}
</style>