import * as axios from 'axios'
import { getJwtToken /*, tokenIsValid, refreshToken*/ } from '@/services/auth'

export default async function(payload, path) {
  // if (!tokenIsValid()) {
  //  console.log('not valid')
  //  await refreshToken()
  // }
  axios.defaults.headers.common.Authorization = getJwtToken()
  const params = {
    method: 'post',
    url: process.env.VUE_APP_EXEC_API + '/' + path,
    data: payload
  }
  return await axios(params)
}