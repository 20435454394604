<template>
  <div class="columns">
    <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
      <SiteNav :user="user" />
      <div v-if="isEditor" class="content-area">
        <div v-if="successTxt" class="text-center successBox">
          {{successTxt}}
        </div>
        <div v-if="errorTxt" class="text-center warningBox">
          {{errorTxt}}
        </div>
        <div class="field-area">
          <div class="form-group">
            <label class="form-label" for="scoutId">Scout ID</label>
            <input id="scoutId" class="form-input" v-model="scoutId" type="text" data-length="10" style="width: 100px">
          </div>
        </div>
        <div class="field-area">
          <div class="form-group">
            <label class="form-label" for="points">Total points</label>
            <input id="points" class="form-input" v-model="points" type="text" data-length="10" style="width: 100px">
          </div>
        </div>
        <div class="field-area">
          <div class="form-group">
            <label class="form-label" for="note">Optional note</label>
            <input id="note" class="form-input" v-model="note" type="text" data-length="10" style="width: 300px">
          </div>
        </div>
        <button id="grant-btn" class="btn btn-primary btn-sm" @click="grantPoints()">Grant</button>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config'
import SiteNav from '@/components/SiteNav.vue'
import emitter from '@/lib/general/emitter'
import postAsync from '@/services/post-async'
import handleError from '@/services/handle-error'
const nestedProperty = require('nested-property')
export default {
  name: 'ControlPage',
  props: {
    user: Object
  },
  components: {
    SiteNav
  },
  data: function() {
    return {
      scoutId: '',
      points: 0,
      note: '',
      successTxt: '',
      errorTxt: ''
    }
  },
  mounted: function() {},
  computed: {
    isEditor: function() {
      return this.user.iplevel <= config.MAX_EDITOR_LEVEL
    }
  },
  methods: {
    async grantPoints() {
      const self = this
      emitter.emit('loading', 'loading')
      try {
        await postAsync({ scoutId: this.scoutId, points: this.points, note: this.note }, 'points/grant')
        self.successTxt = 'Done!'
        self.points = 0
        self.scoutId = ''
      } catch (error) {
        self.errorTxt = nestedProperty.get(error, 'response.data.message')
        handleError(error)
      } finally {
        emitter.emit('loading', '')
      }
    }
  }
}
</script>
<style scoped>
.successBox {
  border: 1px dashed #34a853;
  background: #d0f7da;
  padding: 3px;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}

.warningBox {
  border: 1px dashed #FFB700;
  background: #fffaf7;
  padding: 3px;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}
</style>