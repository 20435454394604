<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth text-center">
          <a @click="$router.push({name: 'HomePage', params: {lang: currentLocale}})" class="navbar-brand" style="margin-top: 2rem; display: block;">Scout Action</a>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-md-8 col-lg-4 col-xl-3 col-2 p-centered">
          <div class="section-spacer"></div>
          <div class="text-center">
            <div class="label label-success mb-2" v-if="confirmation !== null">{{ confirmation }}</div>
            <div class="label label-error mb-2" v-if="error !== null">{{ error }}</div>
            <form @submit.prevent="login">
              <div class="form-group">
                <input id="email" type="text" class="form-input" v-model="email" :placeholder="$t('Email')" required>
              </div>
              <div class="form-group">
                <input id="password" type="password" class="form-input" v-model="pass" :placeholder="$t('Password')" required>
              </div>
              <div class="center-align">
                <button class="btn btn-primary btn-large col-12" :class="[isWorking ? 'disabled loading' : '']"><span v-show="!isWorking">{{$t('LogIn')}}</span></button>
                <div class="mt-2"></div>
                <p>
                  <small>
                    <router-link :to="({name: 'HomePage', params: {lang: currentLocale}})">{{$t('Home')}}</router-link>
                    &middot;
                    <router-link :to="({name: 'RegisterPage', params: {lang: currentLocale}})">{{$t('Register')}}</router-link>
                    <br>
                    <router-link :to="({name: 'ResetPage', params: {lang: currentLocale}})">{{$t('ForgotPassword')}}</router-link>
                  </small>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from '@/lib/general/emitter'
import 'whatwg-fetch'
const nestedProperty = require('nested-property')
import postAsync from '@/services/post-async'
export default {
  name: 'LoginPage',
  title: function() {
    return `Scout Action :: ${this.$t('LogIn')}`
  },
  props: {
    confirmEmail: {
      type: String
    },
    confirmUsername: {
      type: String
    },
    confirmCode: {
      type: String
    }
  },
  data: function() {
    return {
      currentLocale: 'en',
      email: this.confirmEmail ? this.confirmEmail : '',
      pass: '',
      confirmation: null,
      error: null,
      isWorking: false
    }
  },
  beforeCreate: function() {
    document.body.className = 'public-zone'
  },
  mounted: async function() {
    const self = this
    this.currentLocale = localStorage.getItem('lang') || nestedProperty.get(this, '$route.params.lang')
    if (this.confirmEmail && this.confirmUsername && this.confirmCode) {
      const payload = {
        username: this.confirmUsername,
        code: this.confirmCode,
        lang: this.currentLocale || 'en'
      }
      const response = await postAsync(payload, 'user/confirm').catch(error => {
        if (error) {
          self.confirmation = null
          if (error.code) {
            if (error.code === 'CodeMismatchException') {
              self.error = self.$t('ConfirmationError')
            } else if (error.code === 'NotAuthorizedException') {
              self.error = self.$t('AlreadyConfirmed')
            } else {
              self.error = `${self.$t('SomethingWentWrong')} ${self.$t('ContactToReport')}`
            }
          } else {
            self.error = error.message ? error.message : self.$t('SomethingWentWrong')
          }
          return false
        }
      })
      if (response.status === 200) {
        self.confirmation = self.$t('ConfirmationMessage')
      }
    }
  },
  methods: {
    async login() {
      const self = this
      this.isWorking = true
      // const emitter = app.config.globalProperties.emitter
      emitter.emit('loading', 'loading')
      const payloadLogin = {
        username: this.email,
        password: this.pass
      }
      const loginResponse = await postAsync(payloadLogin, 'user/login').catch(error => {
        self.confirmation = null
        self.isWorking = false
        self.error = error.response.data.message
        emitter.emit('loading', '')
        return
      })
      if (loginResponse) {
        emitter.emit('login', loginResponse)
        // setJwtToken(loginResponse.data.idToken)
        // setRefreshToken(loginResponse.data.refreshToken)
        // if (nestedProperty.get(loginResponse, 'data.iplevel') <= config.MAX_EDITOR_LEVEL) {
        //  localStorage.setItem('sa-role', 'scout action team')
        // }
        let lang
        if (localStorage.getItem('lang')) {
          lang = localStorage.getItem('lang')
        } else {
          lang = loginResponse.data.lang || 'en'
          localStorage.setItem('lang', lang)
        }
        // const lang = localStorage.getItem('lang') || loginResponse.data.lang
        const scoutRefId = localStorage.getItem('scoutRefId') || ''
        const redirQuery = self.$route.query.redirect
        const decodedQuery = redirQuery ? decodeURIComponent(redirQuery) : ''
        const payloadCheckin = {
          username: loginResponse.data.username,
          scoutRefId: scoutRefId,
          props: {
            email: loginResponse.data.email,
            lang: self.currentLocale,
            mailing: nestedProperty.get(loginResponse, 'data.mailing')
          }
        }
        const response = await postAsync(payloadCheckin, 'checkin')
        emitter.emit('checkin', response)
        // setTeamId(response.data.teamId)
        // setScoutId(response.data.scoutId)
        // localStorage.setItem('lang', lang)
        // localStorage.removeItem('iplevel')
        self.$router.replace(decodedQuery ? `${decodedQuery}` : '/dash')
      }
    }
  }
}
</script>
<style scoped>
</style>