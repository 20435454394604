<template>
  <div class="columns">
    <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
      <SiteNav :user="user" />
      <div class="card browse-card mb-2">
        <div class="card-body">
          <h5 class="d-block-inline float-left" style="margin-bottom: 0">{{$t('dashOpenCalls')}}</h5>
          <router-link class="btn btn-primary btn-sm float-right" :to=" '/sm' ">{{$t('dashNewCall')}} &rarr;</router-link>
        </div>
      </div>
      <div class="mb-2">
        <ul class="tab tab-block">
          <li class="tab-item" :class="[currentTab === 'OPEN' ? 'active' : '']">
            <!-- <a @click="filterMissions('OPEN')">{{$t('DashOpen')}}</a> -->
            <router-link :to="'/dash'" @click="filterMissions('OPEN')">{{$t('DashOpen')}}</router-link>
          </li>
          <li class="tab-item" :class="[currentTab === 'DRAFT' ? 'active' : '']">
            <!-- <a @click="filterMissions('DRAFT')">{{$t('DashDraft')}}</a> -->
            <router-link :to="'/dash#DRAFT'" @click="filterMissions('DRAFT')">{{$t('DashDraft')}}</router-link>
          </li>
          <li class="tab-item" :class="[currentTab === 'PENDING' ? 'active' : '']">
            <!-- <a @click="filterMissions('PENDING')">{{$t('DashPending')}}</a> -->
            <router-link :to="'/dash#PENDING'" @click="filterMissions('PENDING')">{{$t('DashPending')}}</router-link>
          </li>
          <li class="tab-item" :class="[currentTab === 'CLOSED' ? 'active' : '']">
            <!-- <a @click="filterMissions('CLOSED')">{{$t('DashClosed')}}</a> -->
            <router-link :to="'/dash#CLOSED'" @click="filterMissions('CLOSED')">{{$t('DashClosed')}}</router-link>
          </li>
          <li v-if="isEditor" class="tab-item" :class="[currentTab === 'DELETED' ? 'active' : '']">
            <!-- <a @click="filterMissions('DELETED')">{{$t('DashDELETED')}}</a> -->
            <router-link :to="'/dash#DELETED'" @click="filterMissions('DELETED')">{{$t('DashDeleted')}}</router-link>
          </li>
        </ul>
      </div>
      <!-- list starts here -->
      <div v-if="sortedMissions.length">
        <div class="card browse-card mb-2" v-bind:key="mission.id" v-for="mission of sortedMissions">
          <div class="card-header">
            <router-link v-if="mayParticipate(mission)" class="btn btn-primary btn-sm mb-2 float-right" :to=" '/n/' + mission.id ">{{$t('dashParticipate')}} &rarr;</router-link>
            <a v-if="mission.missionStatus === 'OPEN'" class="btn btn-bold btn-sm mr-2 mb-2 float-right editor-action" @click="toggleShare(mission.id)">{{$t('dashShare')}}</a>
            <h6 class="hide-sm">{{mission.title}}</h6>
            <h5 class="show-sm">{{mission.title}}</h5>
            <span v-if="mission.geoArea" class="sa-tag">{{mission.geoArea}}</span>
            <span v-if="mission.ppae" class="sa-tag">{{mission.ppae}} {{$t('Points')}}</span>
            <span v-if="mission.prize" class="sa-tag">{{$t('Prize')}}: {{mission.prize}} {{$t('Points')}}</span>
          </div>
          <div class="card-body">
            <div class="columns">
              <div class="column col-12">
                <div v-if="mission.media0" class="float-img-wide-only">
                  <span v-if="isOwner(mission)">
                    <router-link :to=" '/mission/' + mission.id ">
                      <span v-if="mission.media0">
                        <img class="img-responsive img-fit-cover card-image" v-lazy="thumbPath + mission.media0" alt="">
                      </span>
                    </router-link>
                  </span>
                  <span v-else>
                    <img class="img-responsive img-fit-cover card-image" v-lazy="thumbPath + mission.media0" alt="">
                  </span>
                  <br v-if="mission.media0">
                </div>
                <div v-html="mission.description"></div><br>
                <p v-if="mission.expDate">
                  (<span v-if="closingDate(mission.expDate)">{{ closingDate(mission.expDate) }}</span>)
                </p>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <!-- <p>{{ mission.item_count || 0 }} items</p> -->
            <div class="d-block float-right p-relative">
              <a v-if="mayDelete(mission)" class="btn btn-secondary btn-sm mr-2 mb-2 warning" v-on:click="delMission(mission)">{{$t('Delete')}}</a>
              <router-link v-if="mayEdit(mission)" class="btn btn-secondary btn-sm mr-2 mb-2 editor-action" :to=" '/em/' + mission.id ">{{$t('Edit')}}</router-link>
              <!-- <router-link v-if="mission.stillActive && mission.formId" class="btn btn-primary btn-sm mr-2 mb-2" :to=" '/n/' + mission.id ">{{$t('dashParticipate')}} &rarr;</router-link> -->
              <router-link v-if="(isOwner(mission) || isEditor) && mayHaveResults(mission)" class="btn btn-primary btn-sm mb-2" :to=" '/mission/' + mission.id ">{{$t('DashSeeResults')}} &rarr;</router-link>
            </div>
          </div>
        </div>
        <div v-if="currentTab==='OPEN'" class="card browse-card mb-2">
          <div class="card-body">
            <div class="columns">
              <div class="column col-12">{{$t('DashTheseAreAll')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showEmpty">
        <div class="card browse-card mb-2">
          <div class="card-body text-center">
            <div style="width:200px; margin: 0 auto;">
              <img v-lazy="'img/scouts.svg'" />
              <div class="mb-2">{{$t('DashNoMissions')}}</div>
              <router-link class="btn btn-primary btn-sm" :to=" '/sm' ">{{$t('dashNewCall')}} &rarr;</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="modal modal-sm" :class="[showShare ? 'active' : '' ]">
        <a class="modal-overlay" aria-label="Close" @click="showShare=false"></a>
        <div class="modal-container">
          <div class="modal-header">
            <button @click="showShare=false" class="btn btn-clear float-right"></button>
          </div>
          <div class="modal-body">
            <div class="content" style="font-size: 0.7rem !important;">
              <div class="mb-2">{{$t('dashShareInstructions')}}</div>
              <a class="d-inline-block mb-2" :href="linkShare" target="_blank">{{linkShare}}</a><br>
              <button id="btn-copy-clipboard" class="btn btn-primary btn-sm mt-2" @click="copyTextToClipboard($event, linkShare)">{{$t('Copy')}}</button>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
      <div id="bottom-space"></div>
    </div>
  </div>
</template>
<script>
import emitter from '@/lib/general/emitter'
const nestedProperty = require('nested-property')
import config from '@/config'
import { funcs } from '@/services/datetime'
import copyTextToClipboard from '@/services/lib/copy-to-clipboard'
import postAsync from '@/services/post-async'
import handleError from '@/services/handle-error'
import SiteNav from '@/components/SiteNav.vue'
const getStatus = function() {
  return ['#OPEN', '#DRAFT', '#PENDING', '#CLOSED', '#DELETED'].indexOf(window.location.hash) !== -1 ?
    window.location.hash.substring(1) :
    'OPEN'
}
const initData = {
  posts: [],
  missions: [],
  missionsIdx: []
}
const loadMissions = async (data, status, teamId, callback) => {
  const payload = teamId ? { missionStatus: `${teamId}#${status}`, teamId: teamId } : { missionStatus: status }
  payload.iplevel = localStorage.getItem('iplevel')
  const response = await postAsync(payload, 'missions')
    .catch(err => {
      if (typeof callback === 'function') {
        return callback(err)
      }
    })
  if (nestedProperty.get(response, 'data')) {
    data.missions = nestedProperty.get(response, 'data.missions')
    data.showEmpty = data.missions.length === 0
    data.missionsIdx = []
    data.missions.map(m => {
      data.missionsIdx.push(m.id)
    })
    data.posts = nestedProperty.get(response, 'data.posts')
  }
  if (response && typeof callback === 'function') {
    return callback(null)
  }
}
export default {
  name: 'DashPage',
  title: function() {
    return `Scout Action :: ${this.$t('Home')}`
  },
  components: {
    SiteNav
  },
  props: {
    user: Object
  },
  data() {
    return {
      ...initData,
      currentTab: 'OPEN',
      cols: [],
      showShare: false,
      thumbPath: process.env.VUE_APP_THUMB_PATH,
      sharedMissionId: null,
      showEmpty: false
    }
  },
  created() {
    document.body.className = 'auth-zone'
  },
  beforeRouteEnter: function(to, from, next) {
    emitter.emit('loading', 'loading')
    const status = to.hash.substring(1) || 'OPEN'
    loadMissions(initData, status, undefined, (err) => {
      handleError(err)
      next()
    })
  },
  computed: {
    linkShare: function() {
      const scoutId = (nestedProperty.get(this, 'user.scoutId') || '').toLowerCase()
      return `${process.env.VUE_APP_DOMAIN}/${this.sharedMissionId}?${scoutId}=1`
    },
    isEditor: function() {
      return this.user.iplevel <= config.MAX_EDITOR_LEVEL
    },
    sortedMissions: function() {
      // const ts = new Date().getTime()
      return this.missions.slice().map(m => {
        // m.stillActive = m.expDate === 0 || m.expDate > ts
        m.description = m.description ? m.description.replaceAll('\n', '<br>') : ''
        m.media0 = (m.media && m.media.length) ? m.media[0] : undefined
        return m
      })
    }
  },
  mounted: function() {
    const status = getStatus()
    if (this.missions.length === 0) {
      this.filterMissions(status)
    } else {
      emitter.emit('loading', '')
      this.currentTab = status
    }
  },
  methods: {
    mayDelete: function(mission) {
      const cond1 = mission.missionStatus === 'DRAFT' && this.isOwner(mission)
      return cond1 || this.isEditor
    },
    mayEdit: function(mission) {
      const cond1 = (mission.missionStatus === 'DRAFT' || mission.missionStatus === 'OPEN') && this.isOwner(mission)
      return cond1 || this.isEditor
    },
    mayParticipate: function(mission) {
      const cond1 = mission.missionStatus === 'OPEN'
      const cond2 = mission.formId !== 'internal'
      const cond3 = !this.isOwner(mission) || this.isEditor
      return cond1 && cond2 && cond3
    },
    mayHaveResults: function(mission) {
      const cond1 = mission.formId !== 'internal'
      const cond2 = this.currentTab === 'OPEN' || this.currentTab === 'CLOSED'
      return cond1 && cond2
    },
    isOwner: function(mission) {
      return this.user.teamId === mission.teamId
    },
    filterMissions: function(status) {
      emitter.emit('loading', 'loading')
      status = status ? status : getStatus()
      this.currentTab = status
      const teamIdInput = status === 'OPEN' || this.isEditor ? undefined : this.user.teamId
      this.missions = []
      this.missionsIdx = []
      loadMissions(this, status, teamIdInput, (err) => {
        handleError(err)
        if (!err) {
          this.currentTab = status
        }
        emitter.emit('loading', '')
      })
    },
    toggleShare: function(missionId) {
      this.showShare = !this.showShare
      this.sharedMissionId = this.showShare ? missionId : null
    },
    copyTextToClipboard: function(evt, text) {
      const self = this
      copyTextToClipboard(evt, text, (err, result) => {
        const targetId = evt.target.id
        if (result) {
          document.getElementById(targetId).innerHTML = self.$t('Copied')
          setTimeout(() => {
            document.getElementById(targetId).innerHTML = self.$t('Copy')
          }, 3000)
        }
      })

    },
    closingDate: function(ts) {
      const currentTs = new Date().getTime()
      if (ts === 0) {
        return null
      } else {
        return ts <= currentTs ? this.$t('Expired') : `${this.$t('ExpiresIn')} ${funcs.humanexp(ts)}`
      }
    },
    async delMission(mission) {
      if (!confirm(this.$t('AreYouSure'))) {
        return false
      }
      const payload = {
        missionId: mission.id
      }
      await postAsync(payload, (this.isEditor && mission.missionStatus === 'DELETED' ? 'deleteMission' : 'softDeleteMission'))
        .catch(err => {
          return handleError(err)
        })
      const idx = this.missionsIdx.indexOf(mission.id)
      if (idx !== -1) {
        this.missions.splice(idx, 1)
        this.missionsIdx.splice(idx, 1)
      }
    }
  }
}
</script>
<style scoped>
.showPost {
  visibility: show;
}

.float-img-wide-only {
  float: right !important;
}

@media only screen and (max-width: 600px) {
  .float-img-wide-only {
    float: none !important;
    /*margin-right: 5px;*/
  }
}
</style>