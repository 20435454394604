import moment from 'moment'
import 'moment/locale/es' // without this line it didn't work

const funcs = {
  localdt(ts, lang = 'es') {
    if (!ts) {
      return 'n/a'
    }
    // const result = moment(ts * 1000).utc().format('dddd, MMM Do YYYY, h:mm:ss a')
    moment.locale(lang)
    const result = moment(ts).utc().format('LLLL')
    return result
  },
  humanexp(ts) {
    return moment().to(ts)
  }
}

export { funcs }