<template>
  <div class="container">
    <div class="columns">
      <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
        <header class="navbar">
          <section class="navbar-header">
            <span>
              <a @click="$router.push({name: 'HomePage', params: {lang: currentLocale}})" class="navbar-brand">Scout Action</a>
            </span>
          </section>
          <section class="navbar-section">
            <a class="mr-2" :class="{'btn-primary': currentLocale === 'en'}" @click="changeLocale('en')">English</a>/
            <a class="ml-2" :class="{'btn-primary': currentLocale === 'es'}" @click="changeLocale('es')">Español</a>
          </section>
        </header>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WebNav',
  /*
  setup() {
    const i18n = useI18n()
    const route = useRoute()
    const userLang = navigator.language || navigator.userLanguage
    let localLang = userLang ? userLang.split('-')[0] : 'en'
    localLang = ['en', 'es'].indexOf(localLang) !== -1 ? localLang : 'en'
    const predefined = localStorage.getItem('lang') ? localStorage.getItem('lang') : localLang
    const requested = route.params && route.params.lang
    const lang = requested ? requested : predefined
    i18n.changeLocale(lang)
    localStorage.setItem('lang', lang)
    initData.currentLocale = lang
    const redir = requested ? false : true
    return {
      redir
    }
  },
  */

  data: function() {
    return {
      currentLocale: localStorage.getItem('lang') || 'en'
    }
  },
  /*
  mounted: function() {
    if (this.redir) {
      this.changeLocale(this.currentLocale)
    }
  },
  */
  methods: {
    changeLocale: function(lang) {
      const route = this.$route
      const parts = route.path.split('/').filter(p => { return (p && p !== 'en' && p !== 'es') })
      parts.unshift(lang)
      this.currentLocale = lang
      this.i18n.changeLocale(lang)
      localStorage.setItem('lang', lang)
      this.$router.push(`/${parts.join('/')}`)
    }
  }
}
</script>