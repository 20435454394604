<template>
  <div class="columns">
    <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
      <SiteNav :user="user" />
      <div v-if="!mission.id || (mission.id && isOwner) || isEditor" class="content-area">
        <div class="text-center warningBox" v-if="getValue(mission, 'missionStatus')==='OPEN'" v-html="$t('NewMissionWarningNeedsPermission')">
        </div>
        <div class="text-center warningBox" v-if="missionIsDraft">
          <b>{{$t('NewMissionDraftWarning')}}</b><br>
          {{totalPts}} {{$t('NewMissionWarningPointsAvailable')}}
        </div>
        <div class="text-center warningBox" v-if="getValue(mission, 'missionStatus')==='PENDING'">
          <b>{{$t('NewMissionPendingWarning')}}</b>
        </div>
        <h5 v-if="!mission.id">{{$t('NewMissionTitle')}}</h5>
        <p v-if="(mission.id || isEditor) && mission.username"><b>Creator:</b> {{mission.username}}</p>
        <div class="field-area">
          <div class="form-group" :class="[fieldFocus['title'] ? (isValid.title ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="title">{{$t('NewMissionName')}}</label>
            <small class="d-block text-dark mb-2">{{$t('NewMissionNameTip')}}</small>
            <input id="title" class="form-input" v-model="mission.title" type="text" @keyup="updateLen('title'); fieldIsOk('title')" @focus="fieldFocus['title']=true; fieldIsOk('title')" @blur="fieldIsOk('title')" :disabled="disableForNonEditors">
            <div class="d-inline-block float-right">
              <div class="socialPreview" v-html="counterText.title"></div>
            </div>
          </div>
        </div>
        <div class="field-area">
          <div class="form-group" :class="[fieldFocus['geoArea'] ? (isValid.geoArea ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="geoArea">{{$t('NewMissionGeoArea')}}</label>
            <small class="d-block text-dark mb-2">{{$t('NewMissionGeoAreaTip')}}</small>
            <input id="geoArea" class="form-input" v-model="mission.geoArea" type="text" data-length="50" style="width: 260px;" @focus="fieldFocus['geoArea']=true; fieldIsOk('geoArea')" @blur="onBlur('geoArea', 'Global'); fieldIsOk('geoArea')" :disabled="disableForNonEditors">
          </div>
        </div>
        <div class="field-area">
          <div class="form-group" :class="[fieldFocus['description'] ? (isValid.description ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="description">{{$t('NewMissionDescriptionOnWebsite')}}</label>
            <small class="d-block text-dark mb-2">{{$t('NewMissionDescriptionOnWebsiteTip')}}</small>
            <textarea id="description" class="form-input" v-model="mission.description" rows="5" data-length="1500" @keyup="updateLen('description'); fieldIsOk('description')" @focus="fieldFocus['description']=true; fieldIsOk('description')" @blur="fieldIsOk('description')" :disabled="disableForNonEditors"></textarea>
            <div class="d-inline-block float-right">
              <div class="socialPreview" v-html="counterText.description"></div>
            </div>
          </div>
        </div>
        <!--         <div class="field-area">
          <div class="form-group">
            <label for="prize" class="form-label">Prize</label>
            <small class="d-inline-block text-dark mb-2">Points offered to the top entry</small>
            <input id="prize" class="form-input" v-model="mission.prize" type="text" data-length="5" :disabled="disableForNonEditors">
          </div>
        </div> -->
        <div class="field-area">
          <div class="form-group" :class="[fieldFocus['ppae'] ? (isValid.ppae ? 'has-success' : 'has-error') : '']">
            <label for="ppae" class="form-label">{{$t('NewMissionPPAE')}} ({{$t('Minimum')}}: {{nestedProperty(template, 'minPoints')}})</label>
            <small class="d-inline-block text-dark mb-2">{{$t('NewMissionPPAETip')}}</small>
            <input id="ppae" class="form-input" v-model="mission.ppae" type="text" data-length="5" @focus="fieldFocus['ppae']=true; fieldIsOk('ppae')" @blur="onBlur('ppae', nestedProperty(template, 'minPoints')); fieldIsOk('ppae')" @keyup="fieldIsOk('ppae')" :disabled="disableForNonEditors">
          </div>
        </div>
        <div class="field-area">
          <div class="form-group" :class="[fieldFocus['maxEntries'] ? (isValid.maxEntries ? 'has-success' : 'has-error') : '']">
            <label for="maxEntries" class="form-label">{{$t('NewMissionMaxEntries')}}</label>
            <small class="d-inline-block text-dark mb-2">{{$t('NewMissionMaxEntriesTip')}}</small>
            <input id="maxEntries" class="form-input" v-model="mission.maxEntries" type="text" data-length="5" @focus="fieldFocus['maxEntries']=true; fieldIsOk('maxEntries')" @blur="onBlur('maxEntries', 1); fieldIsOk('maxEntries')" @keyup="fieldIsOk('maxEntries')" :disabled="disableForNonEditors">
          </div>
        </div>
        <div class="field-area">
          <div class="form-group">
            <label for="maxEntries" class="form-label">{{$t('NewMissionSingleEntry')}}</label>
            <label class="form-switch" for="shareItem">
              <input type="checkbox" v-model="mission.singleEntry" id="shareItem">
              <i class="form-icon"></i> <small class="d-inline-block text-dark mb-2">{{$t('NewMissionSingleEntryTip')}}</small>
            </label>
          </div>
        </div>
        <div v-if="nestedProperty(template, 'props.etags')" class="field-area">
          <div class="form-group">
            <label class="form-label" for="strEtags">Tags allowed in submissions</label>
            <small class="d-inline-block text-dark mb-2">Separate with comma (up to 25 characters long each)</small>
            <input id="strEtags" class="form-input" v-model="mission.strEtags" type="text" :disabled="disableForNonEditors">
          </div>
        </div>
        <div v-if="nestedProperty(template, 'props.itemOptions')" class="field-area">
          <div class="form-group">
            <label class="form-label" for="strOptions">Options required in submissions</label>
            <small class="d-inline-block text-dark mb-2">Use format "option [value]" and separate with semicolons (up to 80 characters long each)</small>
            <input id="strOptions" class="form-input" v-model="mission.strOptions" type="text" :disabled="disableForNonEditors">
          </div>
        </div>
        <div class="field-area">
          <div class="form-group">
            <label class="form-label" for="expDate">{{$t('NewMissionExpirationTime')}}</label>
            <small class="d-inline-block text-dark mb-2">{{$t('NewMissionExpirationTimeTip')}}</small>
            <!-- <input id="expDate" v-model="mission.expDate" type="text" style="width: 130px;"> -->
            <Datepicker v-model="mission.expDate" @update:modelValue="handleDate" :locale="user.lang" :cancelText="$t('Cancel')" :selectText="$t('Select')" :startDate="mission.expDate" :minDate="new Date()" :enableTimePicker="false" :disabled="disableDatePicker" utc></Datepicker>
          </div>
        </div>
        <div class="field-area">
          <div class="form-group" style="margin-bottom: 1rem">
            <label class="form-label">{{$t('NewMissionImage')}}</label>
            <small class="d-block text-dark mb-2">{{$t('NewMissionImageTip')}}</small>
            <div v-if="!disableForNonEditors" ref="myVueDropzone" class="dropzone dropbox" id="sa-dropzone"></div>
            <div v-else>
              <img v-if="nestedProperty(mission, 'media', 0)" class="img-responsive img-fit-cover card-image" v-lazy="thumbPath + mission.media[0]" alt="">
              <img v-else class="img-responsive img-fit-cover card-image" src="/empty.png" alt="">
            </div>
          </div>
        </div>
        <div v-if="isEditor && mission.id" class="field-area">
          <div class="form-group">
            <label class="form-label" for="missionStatus">Status</label>
            <small class="d-inline-block text-dark mb-2">It sets the mission status</small>
            <select class="form-select" id="missionStatus" v-model="mission.missionStatus">
              <option value="" disabled>Choose a status</option>
              <option value="OPEN">OPEN</option>
              <option value="DRAFT">DRAFT</option>
              <option value="PENDING">PENDING</option>
              <option value="CLOSED">CLOSED</option>
              <option value="DELETED">DELETED</option>
            </select>
          </div>
        </div>
        <div id="loading" :class="[isProcessing ? '': 'hide']">
          <progress class="progress" max="100"></progress>
        </div>
        <div v-if="missionIsDraft || getValue(mission, 'missionStatus')==='PENDING'" class="text-center warningBox">
          {{$t('NewMissionWarningPointsRequired')}}: {{requiredPoints}}<span v-if="missingPoints"> ({{$t('NewMissionMissingPoints')}} {{missingPoints}})</span>
        </div>
        <div v-if="missingPoints" class="text-center warningBox" v-html="purchaseMessage()">
        </div>
        <div style="width: 100%; margin-top: 2rem" class="text-center">
          <!-- Leave -->
          <router-link class="btn btn-secondary btn-sm mb-2 mr-2" :to=" '/dash#' + getValue(mission, 'missionStatus') ">
            &larr; {{$t('ActionReturnToDash')}}
          </router-link>
          <!-- Save for later -->
          <button v-if="enableDraft" id="save-btn" class="btn btn-secondary btn-sm mb-2 mr-2" @click="readyCreate('DRAFT')" :disabled="isProcessing">
            {{$t('SaveForLater')}}
          </button>
          <!-- Create -->
          <button v-if="enableCreate" id="create-btn" class="btn btn-primary btn-sm mb-2 mr-2" @click="readyCreate('PENDING')" :disabled="isProcessing">
            {{$t('Create')}}
          </button>
          <!-- Save changes -->
          <button v-if="enableSaveChanges" class="btn btn-secondary btn-sm mb-2 mr-2" @click="readyCreate(mission.missionStatus)" :disabled="isProcessing">
            {{$t('Save')}}
          </button>
        </div>
      </div>
      <div v-else class="empty">
        <div class="empty-icon">
          <i class="icon icon-emoji icon-2x"></i>
        </div>
        <p class="empty-title h5">Nothing to see here</p>
        <p class="empty-subtitle">Contact us if you want to report this.</p>
        <div class="empty-action">
          <a href="mailto:hi@scoutaction.com">hi@scoutaction.com</a>
        </div>
      </div>
      <div id="bottom-space"></div>
    </div>
  </div>
</template>
<script>
import emitter from '@/lib/general/emitter'
import { useRoute } from 'vue-router'
const { serializeError } = require('serialize-error')
import config from '@/config'
import moment from 'moment'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import SiteNav from '@/components/SiteNav.vue'
import { Dropzone } from 'dropzone'
import truncate from '@/lib/mission/truncate'
import { loadMission } from '@/lib/mission/load'
import validate from '@/lib/mission/validate'
import splitTags from '@/lib/mission/split-tags'
import { submitWithImage, submitWithoutImage } from '@/lib/mission/submit'
const nestedProperty = require('nested-property')
import handleError from '@/services/handle-error'
const templates = require(`@/lib/mission/templates.js`)()
import { getUserPoints } from '@/lib/user/points'

let dropzone
Dropzone.autoDiscover = false // prevents 'Dropzone already attached' error
const initData = {
  mission: {},
  missionId: null,
  counterText: {

  },
  maxLength: {
    title: 31,
    description: 82
  },
  expDate: null,
  template: {
    props: null
  },
  fieldFocus: {},
  totalPts: 0
}

const disableAll = function() {
  const allEl = document.querySelectorAll('input,select,textarea')
  for (const input of allEl) {
    input.disabled = (input.id !== 'iplevelSelector')
  }
  // https://github.com/dropzone/dropzone/issues/1519
  Dropzone.instances.forEach(dz => dz.destroy())
}

export default {
  name: 'NewMission',
  title: function() {
    return `Scout Action :: ${this.$t('NewMissionTitle')}`
  },
  components: {
    SiteNav,
    Datepicker
  },
  setup() {
    const handleDate = (modelData) => { console.log(modelData) }
    return { handleDate }
  },
  props: {
    user: Object
  },
  data: function() {
    return {
      ...initData,
      thumbPath: process.env.VUE_APP_THUMB_PATH,
      paymentLink: `${process.env.VUE_APP_STRIPE_PAYMENT_LINK}?client_reference_id=${this.user.userId}`,
      isValid: {
        title: false,
        description: false,
        ppae: false,
        maxEntries: false,
        geoArea: false
      },
      isProcessing: false
    }
  },
  async beforeRouteEnter(to, from, next) {
    emitter.emit('loading', 'loading')
    initData.missionId = nestedProperty.get(to, 'params.missionId')
    try {
      initData.mission = await loadMission(initData.missionId)
      initData.mission.formId = initData.missionId ? initData.mission.formId : to.params.formId
      if (!initData.mission.formId) {
        throw new Error('ErrorMissingTemplateId')
      }
      initData.template = templates[initData.mission.formId]
      initData.totalPts = await getUserPoints(initData.mission.userId)
    } catch (error) {
      emitter.emit('loading', '')
      disableAll()
      handleError(error)
      return next(false)
    }
    next()
  },
  created: function() {
    document.body.className = 'auth-zone'
  },
  async mounted() {
    const self = this
    const route = useRoute()
    const routeMissionId = nestedProperty.get(route, 'params.missionId')
    try {
      if (!nestedProperty.get(this, 'mission.id') && routeMissionId) {
        this.mission = await loadMission(routeMissionId)
      }
      self.totalPts = await getUserPoints(this.mission.userId)
    } catch (error) {
      return handleError(error)
    }
    const templateName =
      nestedProperty.get(self, 'mission.formId') ?
      nestedProperty.get(self, 'mission.formId') :
      nestedProperty.get(route, 'params.formId')
    if (!templateName) {
      disableAll()
      return handleError(serializeError(new Error('ErrorMissingTemplateId')))
    }
    self.template = templates[templateName]
    self.mission.missionStatus = self.mission.missionStatus ? self.mission.missionStatus : 'DRAFT'
    self.mission.teamId = self.mission.teamId ? self.mission.teamId : this.user.teamId
    self.mission.ppae = self.mission.ppae ? self.mission.ppae : this.template.minPoints
    self.mission.maxEntries = self.mission.maxEntries ? self.mission.maxEntries : 1
    for (let k in this.isValid) {
      this.fieldIsOk(k)
      this.fieldFocus[k] = true
    }
    emitter.emit('loading', '')
    self.updateLen('title')
    self.updateLen('description')
    if (document.getElementById('sa-dropzone')) {
      const options = {
        url: '/',
        autoProcessQueue: false,
        autoQueue: false,
        addRemoveLinks: true,
        clickable: true,
        maxFiles: 1,
        acceptedFiles: 'image/*'
      }
      dropzone = new Dropzone('div#sa-dropzone', options)
      const btn = document.querySelector('.dz-button')
      if (btn) {
        btn.innerText = self.$t('NewMissionImageText')
      }
      dropzone.on('addedfile', function(file) {
        file.previewElement.addEventListener('click', function() {
          dropzone.removeFile(file)
        })
      })
    }
    emitter.on('pointsUpdate', (data) => {
      // console.log(data)
      self.totalPts = data.points
    })
  },
  computed: {
    missingPoints: function() {
      return (this.requiredPoints > this.totalPts) ? this.requiredPoints - this.totalPts : 0
    },
    isOwner: function() {
      return (this.user && this.mission) ? (this.user.teamId === this.mission.teamId) : false
    },
    isEditor: function() {
      return this.user.iplevel <= config.MAX_EDITOR_LEVEL
    },
    enableDraft: function() {
      const statusCond1 = !nestedProperty.get(this, 'mission.missionStatus')
      const statusCond2 = nestedProperty.get(this, 'mission.missionStatus') === 'DRAFT'
      const userLevelCond1 = this.isEditor
      const userLevelCond2 = this.isOwner
      return (statusCond1 || statusCond2) && (userLevelCond1 || userLevelCond2)
    },
    enableCreate: function() {
      const cond1 = this.totalPts > 0 && this.requiredPoints > 0
      const cond2 = this.totalPts >= this.requiredPoints // user has enough points
      const cond3 = nestedProperty.get(this, 'mission.missionStatus') &&
        nestedProperty.get(this, 'mission.missionStatus') === 'DRAFT'
      return cond1 && cond2 && cond3
    },
    requiredPoints: function() {
      return (this.mission.maxEntries || 0) * (this.mission.ppae || 0)
    },
    disableForNonEditors: function() {
      return !this.isEditor && this.mission.missionStatus !== 'DRAFT'
    },
    disableDatePicker: function() {
      return !this.isEditor && (this.mission.missionStatus === 'CLOSED' || this.mission.missionStatus === 'PENDING')
    },
    enableSaveChanges: function() {
      const cond1 = this.isEditor
      const cond2 = (this.mission.missionStatus === 'OPEN' || this.mission.missionStatus === 'PENDING')
      const cond3 = this.mission.missionStatus !== 'DRAFT'
      return (cond1 || cond2) && cond3
    },
    missionIsDraft: function() {
      return this.mission.missionStatus === 'DRAFT'
    }
  },
  beforeUnmount: function() {
    for (let k in this.mission) {
      delete this.mission[k]
    }
    this.missionId = null
  },
  methods: {
    purchaseMessage: function() {
      return this.$t('NewMissionPurchasePointsIsEasy').replace('{{paymentLink}}', this.paymentLink)
    },
    getValue: function(object, string, defaultValue = '') {
      return nestedProperty.get(object, string, defaultValue)
    },
    onBlur: function(prop, defaultVal) {
      this.mission[prop] = this.mission[prop] ? this.mission[prop] : defaultVal
    },
    fieldIsOk: function(prop) {
      return validate(this, prop)
    },
    nestedProperty: function(obj, prop) {
      return nestedProperty.get(obj, prop)
    },
    updateLen: function(field) {
      let max = this.maxLength[field]
      let preview = truncate(this.mission[field], max)
      this.counterText[field] = `<b>${preview.text}</b> (${preview.len} / ${max})`
    },
    readyCreate(missionStatus) {
      const self = this
      if (!this.mission.title) {
        alert(this.$t('NewMissionErrorMissingTitle'))
        return false
      }
      if (this.mission.missionStatus !== 'DRAFT') {
        if (!this.mission.description) {
          alert('Your mission needs a description!')
          return false
        }
        for (let k in this.isValid) {
          if (!this.isValid[k]) {
            alert(this.$t('NewMissionErrorSubmission'))
            return false
          }
        }
      }
      emitter.emit('loading', 'loading')
      this.isProcessing = true
      let payload = {
        created: this.mission.created,
        title: this.mission.title,
        geoArea: this.mission.geoArea,
        description: this.mission.description,
        expDate: this.mission.expDate ? parseInt(moment(this.mission.expDate).format('x')) : 0, // Number(this.mission.expDate),
        prize: Number(this.mission.prize || 0),
        ppae: Number(this.mission.ppae || 0),
        maxEntries: Number(this.mission.maxEntries || 0),
        formId: this.mission.formId,
        missionTag: this.mission.missionTag || undefined,
        etags: splitTags(this.mission.strEtags, 25, ','),
        itemOptions: splitTags(this.mission.strOptions, 80, ';'),
        teamId: this.user.teamId,
        singleEntry: this.mission.singleEntry,
        missionStatus: missionStatus
      }
      if (self.mission.id) {
        payload.missionId = self.mission.id
        payload.isExistingMission = true
      }
      const files = dropzone ? dropzone.getAcceptedFiles() : null
      const nextPage = missionStatus === 'PENDING' && !self.isEditor ? '/success-mission' : `/dash#${missionStatus}`
      if (!files || !files.length) {
        submitWithoutImage(self, payload, () => {
          emitter.emit('loading', '')
          self.$router.replace(nextPage)
        })
      } else {
        submitWithImage(self, payload, files, () => {
          emitter.emit('loading', '')
          self.$router.replace(nextPage)
        })
      }
    }
  }
}
</script>
<style scoped>
input,
textarea {
  border: 1px solid #ddd;
}

h5 {
  margin: 1.5rem 0 1.5rem 0;
}

.socialPreview {
  font-size: 0.65rem;
  line-height: 0.7rem;
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
  background-color: #eee;
}

.dropzone,
.dz-button {
  padding: 1rem !important;
  font-size: 0.7rem !important;
}
</style>