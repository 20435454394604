<template>
  <div>
    <div class="columns">
      <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
        <SiteNav :user="user" />
        <div class="card browse-card mb-2">
          <div class="card-body">
            <h5>{{mission.title}}</h5>
            <p v-if="mission.expDate">{{ closingDate(mission.expDate) }}</p>
          </div>
        </div>
        <div class="mb-2">
          <ul class="tab tab-block">
            <li class="tab-item" :class="[currentTab === '' ? 'active' : '']">
              <!-- <router-link :to=" '/m/' + mission.id " custom v-slot="{ navigate, href }">
              <a :href="href" @click="navigate">{{$t('AllResults')}}</a>
            </router-link> -->
              <a @click="filterMissionItems(null)">{{$t('AllResults')}}</a>
            </li>
            <li class="tab-item" :class="[currentTab === ITEM_STATUS_APPROVED ? 'active' : '']">
              <a @click="filterMissionItems(ITEM_STATUS_APPROVED)">{{$t('Approved')}}</a>
            </li>
            <li class="tab-item" :class="[currentTab === ITEM_STATUS_VOIDED ? 'active' : '']">
              <a @click="filterMissionItems(ITEM_STATUS_VOIDED)">{{$t('Rejected')}}</a>
            </li>
          </ul>
        </div>
        <!-- ==================================== -->
        <!-- ========= LIST OF RESULTS ========== -->
        <!-- ==================================== -->
        <div v-if="isMissionOwner || isEditor">
          <div v-bind:key="item.id" v-for="item of sortedItems">
            <div v-if="item.teamAction==ITEM_STATUS_VOIDED && currentTab !== ITEM_STATUS_VOIDED" class="passedItem mb-2">
              <p><b>{{item.title}}</b></p>
              <a class="m-2" v-on:click="setStatus(item.id, ITEM_STATUS_PENDING)">{{$t('ActionRemoveFromRejected')}}</a>
            </div>
            <div v-else>
              <div class="card browse-card mb-2">
                <div class="card-header">
                  <h5>{{item.title}} <img v-if="item.teamAction===ITEM_STATUS_APPROVED" src='/img/check.svg' class='smallIcon'></h5>
                  <div class="d-block mb-2">
                    <div v-bind:key="tag" v-for="tag of item.etags" class="sa-tag">
                      {{tag}}
                    </div><br v-if="item.etags">
                  </div>
                </div>
                <div class="card-body">
                  <div class="columns">
                    <div class="column col-md-12" :class="[item.isImage ? 'col-6' : 'col-12']">
                      <div v-if="item.description">{{item.description}}</div>
                      <a v-if="item.isFile" :href="uploadPath + item.media0" target="_blank">{{$t('ResultsPageFile')}}</a>
                    </div>
                    <div v-if="item.isImage">
                      <div v-if="!item.description" class="column col-mx-auto"></div>
                      <div class="column" :class="[item.description ? 'col-md-12 col-6' : 'col-6']">
                        <div class="card-image">
                          <router-link :to="`/p/${item.id}`">
                            <span v-if="item.isImage">
                              <img class="img-responsive img-fit-cover card-image" v-lazy="thumbPath + item.media0" :alt="item.title">
                            </span>
                          </router-link>
                        </div>
                      </div>
                      <div v-if="!item.description" class="column col-mx-auto"></div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-block float-right p-relative">
                    <a v-if="isEditor" class="btn btn-secondary btn-sm mr-2 mb-2 warning" v-on:click="delItem(item.id)">{{$t('Delete')}}</a>
                    <span v-if="item.teamAction===ITEM_STATUS_APPROVED">
                      <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="setStatus(item.id, ITEM_STATUS_PENDING)">{{$t('ActionRemoveFromApproved')}}</a>
                    </span>
                    <span v-else>
                      <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="setStatus(item.id, ITEM_STATUS_APPROVED)">{{$t('ActionApprove')}}</a>
                    </span>
                    <span v-if="item.teamAction===ITEM_STATUS_VOIDED">
                      <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="setStatus(item.id, ITEM_STATUS_PENDING)">{{$t('ActionRemoveFromRejected')}}</a>
                    </span>
                    <span v-else>
                      <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="modalItemId = item.id">{{$t('ActionReject')}}</a>
                    </span>
                    <router-link class="btn btn-secondary btn-sm mr-2 mb-2" :to=" '/p/' + item.id ">{{$t('ActionViewDetails')}} &rarr;</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ==================================== -->
        <!-- ==================================== -->
        <!-- ==================================== -->
        <div v-else>
          <div class="missionNoAuth mb-2">
            <p><b>{{$t('ResultsPageNotAuthorizedToSee')}}</b></p>
          </div>
        </div>
        <div class="card browse-card mb-2">
          <div class="card-body">
            <router-link class="btn btn-secondary btn-sm ml-2" :to=" '/dash' ">&larr; {{$t('ActionReturnToDash')}}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal-sm" :class="[modalItemId ? 'active' : '']">
      <a href="#close" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container pt-2">
        <span>{{$t('ResultsPageMessageForContributor')}}:</span>
        <textarea ref="teamActionNote" class="mb-2" rows="4" style="width: 100%;"></textarea>
        <a class="btn btn-primary btn-sm mr-2 mb-2" v-on:click="setStatus(modalItemId, ITEM_STATUS_VOIDED)">{{$t('ActionReject')}}</a>
        <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="modalItemId=null">{{$t('Cancel')}}</a>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config'
import emitter from '@/lib/general/emitter'
const nestedProperty = require('nested-property')
import moment from 'moment'
import constants from '@/config/const'
import SiteNav from '@/components/SiteNav.vue'
import truncate from '@/services/lib/truncate'
import { teamAction } from '@/services/team-action'
import { funcs } from '@/services/datetime'
import postAsync from '@/services/post-async'
import handleError from '@/services/handle-error'
const templates = require(`@/lib/mission/templates.js`)()
const initData = {
  ...constants,
  mission: {},
  stillActive: true,
  posts: [],
  postsIdx: [],
  totalPosts: 0,
  showTag: {},
  tagCount: {},
  isMissionOwner: false,
  modalItemId: null,
  missionTemplate: null,
  loaded: false
}

const loadItems = async (data, missionId, _teamAction, callback) => {
  const teamId = localStorage.getItem('teamId')
  const payload = {
    missionId: missionId,
    teamId: teamId
  }
  if (_teamAction) {
    payload.teamAction = _teamAction
  }
  const response = await postAsync(payload, (_teamAction ? 'mission/filtered' : 'mission/results'))
    .catch(err => {
      if (typeof callback === 'function') {
        return callback(err)
      }
    })
  if (!response.data || !response.data.mission) {
    if (typeof callback === 'function') {
      return callback(new Error('Mission not found'))
    }
    return false
  }
  data.mission = response.data.mission
  if (nestedProperty.get(data, 'mission.formId')) {
    data.missionTemplate = templates[data.mission.formId]
  }
  data.isMissionOwner = data.mission.teamId === teamId
  data.mission.description = data.mission.description.replaceAll('\n', '<br>')
  if (data.mission.etags) {
    data.mission.etags.map(function(t) {
      data.showTag[t] = true
      data.tagCount[t] = 0
    })
  }
  data.stillActive = data.mission.exp === 0 || data.mission.exp > moment().unix()
  data.posts = response.data.items
  data.postsIdx = data.posts.map(item => item.id)
  data.posts.map(function(p, i) {
    data.postsIdx.push(p.id)
    data.posts[i].show = true
    data.posts[i].teamAction = p.teamAction ? p.teamAction : (_teamAction ? _teamAction : undefined)
    if (p.etags) {
      p.etags.map(x => {
        data.tagCount[x]++
      })
    }
  })
  data.totalPosts = response.data.totalPosts || 0
  data.loaded = true
  if (typeof callback === 'function') {
    return callback(null)
  }
}

export default {
  name: 'MissionPage',
  title: function() {
    return `Scout Action :: ${this.$t('ResultsPageTitle')}`
  },
  components: {
    SiteNav
  },
  props: {
    user: Object
  },
  data() {
    return {
      ...initData,
      missionId: '',
      thumbPath: process.env.VUE_APP_THUMB_PATH,
      uploadPath: process.env.VUE_APP_UPLOAD_PATH,
      showAccepted: true,
      showPending: true,
      currentTab: ''
    }
  },
  beforeRouteEnter: function(to, from, next) {
    emitter.emit('loading', 'loading')
    loadItems(initData, to.params.missionId, null, (err) => {
      if (err) {
        emitter.emit('loading', '')
        return false
      }
      next()
    })
  },
  created: function() {
    document.body.className = 'auth-zone'
    this.missionId = this.$route.params.missionId
  },
  computed: {
    isEditor: function() {
      return this.user.iplevel <= config.MAX_EDITOR_LEVEL
    },
    sortedItems: function() {
      const self = this
      return this.posts.slice()
        .map(item => {
          if (self.missionTemplate) {
            item.title = typeof self.missionTemplate.resultTitle === 'function' ?
              self.missionTemplate.resultTitle(item) : item.title
          }
          const descriptionLen = item.media0 ? 313 : 600
          item.description = item.description ? truncate(item.description, descriptionLen, true) : ''
          item.isImage = item.media0 ? ['.webp', '.png', '.jpg', '.jpeg', '.gif'].some(el => item.media0.includes(el)) : false
          item.isFile = item.media0 ? ['.pdf'].some(el => item.media0.includes(el)) : false
          return item
        })
    }
  },
  mounted: function() {
    if (!this.loaded) {
      loadItems(this, this.missionId, null, () => {
        emitter.emit('loading', '')
      })
    } else {
      emitter.emit('loading', '')
    }
  },
  methods: {
    filterMissionItems: function(action) {
      emitter.emit('loading', 'loading')
      loadItems(this, this.missionId, action, (err) => {
        handleError(err)
        if (!err) {
          this.currentTab = action ? action : ''
        }
        emitter.emit('loading', '')
      })
    },
    async setStatus(itemId, action) {
      const self = this
      emitter.emit('loading', 'loading')
      const notes = action === self.ITEM_STATUS_VOIDED ? this.$refs.teamActionNote.value : ''
      await teamAction(this.missionId, itemId, action, notes)
        .catch(err => {
          return handleError(err)
        })
        .finally(() => {
          emitter.emit('loading', '')
        })
      self.setItemProp(itemId, 'teamAction', (action.startsWith(self.ITEM_STATUS_PENDING) ? undefined : action), function() {
        const idx = self.postsIdx.indexOf(itemId)
        if (idx !== -1 && action === self.ITEM_STATUS_PENDING && self.currentTab !== '') {
          self.posts.splice(idx, 1)
          self.postsIdx.splice(idx, 1)
        }
      })
      self.modalItemId = null
      self.$refs.teamActionNote.value = ''
    },
    setItemProp: function(itemId, prop, value, cb) {
      const idx = this.postsIdx.indexOf(itemId)
      if (idx !== -1) {
        // console.log(itemId, prop, value, this.posts[idx])
        if (value === undefined) {
          delete this.posts[idx][prop]
        } else {
          this.posts[idx][prop] = value
        }
        cb()
      }
    },
    removeElement: function(index) {
      this.posts.splice(index, 1);
    },
    normalize(str) {
      return str.replace(/[^a-z0-9-]/gi, '')
    },
    showAll() {
      this.posts.map(function(p) {
        p.show = true
      })
    },
    filterItems(prop) {
      this.posts.map(function(p) {
        // let tagCond = false
        if (p.etags) {
          p.show = (p.etags.indexOf(prop) !== -1)
        } else {
          p.show = false
        }
      })
    },
    async delItem(itemId) {
      if (!confirm('Are you sure?')) {
        return false
      }
      let idx = this.postsIdx.indexOf(itemId)
      if (idx === -1) {
        return false
      }
      const payload = {
        itemId: itemId
      }
      const response = await postAsync(payload, 'item/delete')
        .catch(err => {
          console.log(err)
          return false
        })
      console.log(response)
      this.posts.splice(idx, 1)
      this.postsIdx.splice(idx, 1)
    },
    dt(m, ts) {
      return funcs.localdt(m, ts)
    },
    closingDate: function(ts) {
      const currentTs = new Date().getTime()
      if (ts === 0) {
        return null
      } else {
        return ts !== 0 && ts <= currentTs ? 'Cerrada' : `Cierra ${funcs.humanexp(ts)}`
      }
    }
  }
}
</script>
<style>
.missionNoAuth {
  border: 1px solid #ff0000;
  text-align: center;
  color: #e57c7c;
  padding: 1rem;
  background-color: #ffe8e8;
  font-size: 0.7rem;
  margin: 1px;
}

.passedItem {
  border: 1px solid #dadee4;
  text-align: center;
  color: #aaa;
  padding: 1rem;
  background-color: #efefef;
  font-size: 0.7rem;
}

.passedItem a {
  color: #bbb !important;
  text-decoration: underline;
}

.postTitle {
  display: block;
  font-weight: 600;
}

.tab ul,
.tab li {
  list-style-type: none !important;
}

.tab .tab-item a.active,
.tab .tab-item.active a {
  border-bottom-color: #407BFF !important;
}
</style>
<style scoped>
.optionsLink {
  float: left;
  margin-right: 10px;
}

ul {
  list-style-type: disc;
  list-style-position: outside;
}

ul li {
  list-style-type: disc;
  margin-left: 1rem;
}

.img-card {
  display: none;
}

.img-row {
  display: none;
}

.showPost {
  display: block;
}

.adminOption {
  display: inline-block;
  margin-left: 10px;
}
</style>