import handleError from '@/services/handle-error'
import shortId from '@/services/short-id'
import { newMission } from '@/services/new-mission.service'
import { upload } from '@/services/upload'

function submitWithImage(ctx, payload, files, callback) {
  let missionId = ctx.mission.id ? ctx.mission.id : shortId()
  return files.reduce(function(p, file) {
    return p.then(function(_) {
      return upload(file, missionId)
        .catch(handleError)
        .then((id) => {
          _.push(id)
          if (_.length === files.length) {
            payload.media = _ // prop 'media' only added if there are attachments
            return newMission(payload)
              .catch(handleError)
              .then(callback)
          }
          return _
        })
    });
  }, Promise.resolve([]))
}

function submitWithoutImage(ctx, payload, callback) {
  return newMission(payload)
    .catch(handleError)
    .then(callback)
}

export {
  submitWithImage,
  submitWithoutImage
}