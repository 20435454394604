<template>
  <div>
    <WebNav />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="section-spacer"></div>
          <div class="section-spacer"></div>
          <!-- <br class="show-sm"> -->
          <h1 class="hide-sm" v-html="$t('mainTitle')"></h1>
          <h2 class="show-sm" v-html="$t('mainTitle')"></h2>
          <p v-html="$t('mainText')"></p>
          <br>
          <button class="btn btn-primary btn-large mt-2" style="margin-right: 13px; width: 200px" @click="$router.push({name: 'RegisterPage', params: {lang: lang}})">{{$t('Register')}}</button>
          <button class="btn btn-primary btn-large mt-2" style="width: 200px" @click="$router.push({name: 'LoginPage', params: $route.params})">{{$t('LogIn')}}</button>
          <br><br>
          <div class="section-spacer"></div>
          <div class="section-spacer"></div>
          <!-- <div class="divider"></div> -->
          <h3>{{$t('homeHowItWorksTitle')}}</h3>
          <div class="columns">
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/scouts.svg" class="illustration show-sm">
                <span v-html="$t('homeHowItWorks')"></span>
              </div>
            </div>
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/scouts.svg" class="illustration hide-sm">
                <!-- <div class="section-spacer hide-sm"></div> -->
              </div>
            </div>
          </div>
          <div class="section-spacer show-sm"></div>
          <div class="columns">
            <div class="column col-lg-12 col-6">
              <div class="col-container" style="margin-top: 4rem;">
                <img src="/img/everyone.svg" class="illustration hide-sm">
              </div>
            </div>
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <h3>{{$t('homeWhoIsThisForTitle')}}</h3>
                <img src="/img/everyone.svg" class="illustration show-sm">
                <span v-html="$t('homeWhoIsThisFor')"></span>
                <div style="margin-top: 1rem;">
                  <button class="btn btn-sm mr-2" style="" @click="$router.push({name: 'HelpPage', params: {lang: lang}})">{{$t('ReadMore')}} &rarr;</button>
                  <button class="btn btn-sm ml-2" style="" @click="$router.push({name: 'RegisterPage', params: {lang: lang}})">{{$t('Register')}} &rarr;</button>
                </div>
                <!-- <div class="section-spacer hide-sm"></div> -->
              </div>
            </div>
          </div>
          <!-- <div class="columns">
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/scouts.svg" class="illustration">
                <h3>{{$t('ourScoutsTitle')}}</h3>
                <p v-html="$t('ourScoutsText')"></p>
                <button class="btn btn-sm my-2" style="margin-right: 13px" @click="$router.push({name: 'ScoutsPage', params: {lang: lang}})">{{$t('ourScoutsLearnMore')}} &rarr;</button>
                <div class="section-spacer show-sm"></div>
              </div>
            </div>
            <div class="column col-lg-12 col-6">
              <div class="col-container">
                <img src="/img/platform.svg" class="illustration">
                <h3>{{$t('ourPlatformTitle')}}</h3>
                <p v-html="$t('ourPlatformText')"></p>
                <button class="btn btn-sm my-2" style="margin-right: 13px" @click="$router.push({name: 'AboutPage', params: {lang: lang}})">{{$t('ourPlatformLearnMore')}} &rarr;</button>
              </div>
            </div>
          </div> -->
          <div class="section-spacer"></div>
          <!--
          <div class="divider"></div>
          <div class="section-spacer"></div>
          <div class="columns">
            <div class="column col-12">
              <div class="col-container">
                <h3>{{$t('homeOpenCallsTitle')}}</h3>
                <p>{{$t('homeOpenCallsText')}}</p>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column col-12">
              <div class="col-container">
                <img src="https://scout-app-thumb-prod.s3-us-west-2.amazonaws.com/v31y6bf/gqek14o.png" class="partner-logo float-left mr-2 mb-2">
                <img src="https://scout-app-thumb-dev.s3-us-west-2.amazonaws.com/0zjpliw/l20bz7v.jpeg" class="partner-logo float-left mr-2">
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column col-12">
              <div class="section-spacer"></div>
              <button class="btn btn-sm my-2" style="margin-right: 13px" @click="$router.push({name: 'RegisterPage', params: {lang: lang}})">{{$t('Register')}} &rarr;</button><button class="btn btn-sm my-2" style="margin-right: 13px" @click="$router.push({name: 'LoginPage', params: {lang: lang}})">{{$t('LogIn')}} &rarr;</button>
            </div>
          </div>
          <div class="section-spacer"></div>
        -->
          <!-- footer -->
          <FooterNav page="HomePage" />
          <!-- footer -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { useRoute } from 'vue-router'
// import { router } from '@/router'
import truncate from '@/services/lib/truncate'
// import handleError from '@/services/handle-error'
import WebNav from '@/components/WebNav.vue'
import FooterNav from '@/components/FooterNav.vue'
export default {
  name: 'HomePage',
  title: function() {
    return `Scout Action :: ${this.$t('Home')}`
  },
  components: {
    WebNav,
    FooterNav
  },
  setup() {
    // const route = useRoute()
    const lang = 'es'
    // console.log(route, route.params)
    // const lang = route.params ? route.params.lang : 'en'
    return { lang }
  },
  data: function() {
    return {
      mobile: false,
      firstColWidth: 0,
      truncate: truncate,
      boards: [],
      boardsIdx: []
    }
  },
  beforeCreate: function() {
    document.body.className = 'public-zone'
  },
  mounted: function() {
    /*
    const obj = this.$route.query
    if (obj) {
      try {
        for (let k in obj) {
          localStorage.setItem('scoutRefId', k.toUpperCase())
        }
        let hostname = 'scoutaction.com'
        let scoutRefId = localStorage.getItem('scoutRefId')
        let currentHostname = window.location.hostname
        let endpointId = currentHostname === hostname ? 'kd49wv8ynh' : (currentHostname.includes('local') ? 'm8l8oxw535' : 's5msps3jt2')
        const endpoint = 'https://' + endpointId + '.execute-api.us-west-2.amazonaws.com/'
        const uri = '/logThis?activity=CALL&scoutRefId=' + scoutRefId + '&missionTag=homepage'
        if (scoutRefId) {
          fetch(endpoint + (currentHostname === hostname ? 'prod' : 'dev') + uri).then(response => response.json()).then(data => console.log(data))
        }
      } catch (error) {
        console.log(error)
      }
    }
    */
    window.addEventListener('resize', this.updateCSS)
    this.updateCSS()
  },
  beforeUnmount: function() {
    // this.emitter.off('loadedAllf')
    // this.emitter.off('login')
    // this.emitter.off('logout')
    window.removeEventListener('resize', this.updateCSS)
  },
  methods: {
    updateCSS: function() {
      const el = document.querySelector('.title-cell')
      this.mobile = window.innerWidth < 840
      this.firstColWidth = el ? el.offsetWidth : 0
    }
  }
}
</script>
<style scoped>
@import url('https://rsms.me/inter/inter.css');

:deep(p),
:deep(li) {
  font-family: Inter, sans-serif;
  font-size: 0.90rem !important;
  font-weight: 300 !important;
  color: #4C5059 !important;
}

ul {
  list-style-position: outside;
}

.btn {
  border: 0.05rem solid #407BFF !important;
}

.title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #3B4351 !important;
  font-size: 2.8rem;
  line-height: 2.7rem;
  letter-spacing: -0.1rem;
}

@media screen and (max-width: 840px) {
  .title {
    font-size: 2.1rem;
    line-height: 2rem;
    letter-spacing: -0.1rem;
  }
}

.centered {
  width: 200px;
  height: 50px;
  /*background-color: red;*/
  position: absolute;
  top: 30%;
  left: 50%;
  margin: -25px 0 0 -100px;
}

@media screen and (max-width: 840px) {
  .dropdown .menu {
    min-width: 130px;
  }
}

.input-group-btn {
  border-radius: .1rem !important;
}

.title-cell .icon {
  top: -2px;
  margin-right: 10px;
  color: #5557d9 !important;
}

.illustration {
  width: 100%;
}

.partner-logo {
  border-radius: 5px;
  margin-top: 0.5rem;
  height: 100px !important;
}

@media only screen and (max-width: 600px) {
  .illustration {
    width: 100%;
  }
}

.col-container {
  padding-right: 2rem;
}
</style>