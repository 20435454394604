<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth text-center">
          <a @click="$router.push({name: 'HomePage', params: {lang: lang}})" class="navbar-brand" style="margin-top: 2rem; display: block;">Scout Action</a>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-9 col-mx-auto maxPageWidth">
          <div class="section-spacer"></div>
          <p>{{$t('CheckYourEmailToConfirm')}}</p>
          <div class="section-spacer"></div>
          <button class="btn btn-sm my-2" style="" @click="$router.push({name: 'HomePage', params: {lang: lang}})">&larr; {{$t('backToHomePage')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n-lite'
export default {
  name: 'SuccessRegPage',
  title: function() {
    return `Scout Action :: ${this.$t('Register')}`
  },
  setup() {
    const route = useRoute()
    const lang = route.params ? route.params.lang : 'en'
    return { lang }
  },
  data() {
    return {
      i18n: useI18n()
    }
  },
  mounted: function() {
    const { current } = this.i18n
    let lang = localStorage.getItem('lang') || 'en'
    lang = ['es', 'en'].indexOf(lang) === -1 ? 'en' : lang
    this.currentLocale = current || lang
  },
}
</script>
<style>
</style>