module.exports = () => {
  return {
    'internal': {
      minPoints: 1,
      props: {

      }
    },
    'project-es.v1': {
      lang: 'es',
      title: 'Nueva idea o proyecto',
      instructions: '* campos obligatorios. Si tenés preguntas o necesitas ayuda escribinos a: <a href="mailto:hi@scoutaction.com">hi@scoutaction.com</a>',
      titleItem: 'Acerca del proyecto',
      titlePerson: 'Sobre el responsable del proyecto',
      titleOtherInfo: 'Otra información',
      errorMsg: '',
      successMsg: 'Tu formulario fue guardado con éxito y una confirmación se envió a tu email.',
      nextStep: '<p>El organizador de la convocatoria recibirá todos los formularios enviados cuando termine la búsqueda y contactará a aquellos participantes que cumplan con sus criterios preestablecidos.</p><p>No dejes de volver a visitar Scout Action pronto para ver otras convocatorias abiertas.</p>',
      resultTitle: function(result) { return result.title },
      resultDescription: function(result) { return result.description },
      minPoints: 100,
      props: {
        title: {
          value: '',
          max: 100,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,100}$',
          required: true,
          text: 'Título', // ctx.$t('newItemTitle'),
          hint: 'Título' // ctx.$t('newItemTitleHint')
        },
        description: {
          value: '',
          max: 3000,
          valid: '^[À-ÿ\\w\\s\\S ]{1,3000}$',
          required: true,
          text: 'Descripción', // ctx.$t('newItemDescription'),
          hint: 'Descripción' // ctx.$t('newItemDescriptionHint')
        },
        location: {
          value: '',
          max: 100,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,100}$',
          required: true,
          text: 'Ubicación geográfica del proyecto', // ctx.$t('newItemLocation'),
          hint: 'Localidad, provincia' // ctx.$t('newItemLocationHint')
        },
        itemLink: {
          value: '',
          max: 100,
          valid: '((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
          required: false,
          text: 'Link al website o red social del proyecto (si tiene)', // ctx.$t('newItemLink'),
          hint: 'Ejemplo: https://www.ejemplo.com'
        },
        etags: {
          value: [],
          max: 25,
          valid: '.*',
          testArray: true,
          testProp: 'etagsSel',
          required: false,
          text: 'Categorías', // ctx.$t('newItemCategories'),
          placeholder: 'Categorías disponibles',
          hint: 'Seleccioná una o más categorías' // ctx.$t('newItemCategoriesHint')
        },
        itemOptions: {
          value: [],
          valid: '.+',
          testArray: false,
          testProp: 'itemOption',
          required: true,
          text: 'Etapa en la que se encuentra el proyecto', // ctx.$t('newItemOptions'),
          hint: 'Seleccioná la etapa actual del proyecto' // ctx.$t('newItemOptionsHint')
        },
        files: {
          value: [],
          valid: '.+',
          testArray: true,
          required: false,
          text: 'Imágenes del proyecto', // ctx.$t('newItemImages'),
          placeholder: 'Haz click aquí o arrastra imágenes a este área para seleccionarlas.',
          hint: 'Subir hasta 5 imágenes', // ctx.$t('newItemImagesHint'),
          maxFiles: 5,
          acceptedFiles: 'image/*'
        },
        firstname: {
          value: '',
          max: 32,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,32}$',
          required: true,
          text: 'Nombre', // ctx.$t('newItemFirstName'),
          hint: 'Ingrese su nombre' // ctx.$t('newItemFirstNameHint')
        },
        lastname: {
          value: '',
          max: 32,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,32}$',
          required: true,
          text: 'Apellido', // ctx.$t('newItemLastName'),
          hint: 'Ingrese su apellido' // ctx.$t('newItemLastNameHint')
        },
        email: {
          value: '',
          max: 32,
          valid: '[a-z0-9]+@[a-z]+\\.[a-z]{2,3}',
          required: true,
          text: 'Email', // ctx.$t('newItemEmail'),
          hint: 'Email para recibir notificaciones' // ctx.$t('newItemEmailHint')
        },
        personLink: {
          value: '',
          max: 130,
          valid: '((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
          required: false,
          text: 'Link al website o red social del responsable (si tiene)', // ctx.$t('newItemPersonLink'),
          hint: 'Ejemplo: https://www.ejemplo.com/perfil'
        },
        shareItem: {
          value: false,
          required: false,
          text: 'Si, compartir información', // ctx.$t('newItemYesShareOption'),
          hint: 'Otros inversores y organizaciones que tienen sus propias convocatorias podrían estar interesados. ¿Te gustaría que compartamos tu proyecto con ellos también?' // ctx.$t('newItemShareInfoExplanation')
        }
      }
    },
    'receipt-es.v1': {
      lang: 'es',
      title: 'Comprar en el súper te da puntos!',
      instructions: '',
      titleItem: 'Nuevo recibo de supermercado',
      titlePerson: '',
      // titleOtherInfo: '',
      errorMsg: '',
      successMsg: 'Tu recibo fue guardado con éxito y una confirmación se envió a tu email.',
      nextStep: '',
      resultTitle: function() { return '' },
      resultDescription: function() { return '' },
      minPoints: 3,
      props: {
        files: {
          value: [],
          valid: '.+',
          testArray: true,
          required: true,
          text: 'Foto del recibo',
          placeholder: 'Hacé click aquí o arrastra la foto a este área para seleccionarla.',
          hint: 'Subir una foto de recibo a la vez',
          maxFiles: 1,
          acceptedFiles: 'image/*'
        }
      }
    },
    'photo-es.v1': {
      lang: 'es',
      title: '',
      instructions: '',
      titleItem: 'Nueva foto',
      titlePerson: '',
      // titleOtherInfo: '',
      errorMsg: '',
      successMsg: 'Tu foto fue recibida con éxito y una confirmación se envió a tu email.',
      nextStep: '',
      resultTitle: function() { return '' },
      resultDescription: function() { return '' },
      minPoints: 5,
      props: {
        title: {
          value: '',
          max: 100,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,100}$',
          required: true,
          text: 'Título',
          hint: 'Elegí un título para la foto'
        },
        location: {
          value: '',
          max: 130,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,130}$',
          required: false,
          text: 'Lugar donde se tomó la foto',
          hint: 'Descripción del lugar o lugar geográfico'
        },
        files: {
          value: [],
          valid: '.+',
          testArray: true,
          required: true,
          text: 'Foto',
          placeholder: 'Hacé click aquí o arrastra la foto a este área para seleccionarla.',
          hint: 'Subir una foto a la vez',
          maxFiles: 1,
          acceptedFiles: 'image/*'
        }
      }
    },
    'report-es.v1': {
      lang: 'es',
      title: '',
      instructions: '',
      titleItem: 'Nueva participación',
      titlePerson: '',
      // titleOtherInfo: '',
      errorMsg: '',
      successMsg: 'Tu contribución fue recibida con éxito y una confirmación se envió a tu email.',
      nextStep: '',
      resultTitle: function() { return '' },
      resultDescription: function() { return '' },
      minPoints: 20,
      props: {
        description: {
          value: '',
          max: 3000,
          valid: '^[À-ÿ\\w\\s\\S ]{1,3000}$',
          required: true,
          text: 'Descripción',
          hint: 'Descripción en respuesta a la información pedida en la misión'
        },
        files: {
          value: [],
          valid: '.+',
          testArray: true,
          required: true,
          text: 'Imágenes',
          placeholder: 'Hacé click aquí o arrastra las imágenes a este área para seleccionarlas.',
          hint: 'Subir hasta cinco imágenes',
          maxFiles: 5,
          acceptedFiles: 'image/*'
        }
      }
    },
    'startup-es.v1': {
      lang: 'es',
      title: 'Nuevo startup',
      instructions: '* campos obligatorios. Si tenés preguntas o necesitas ayuda escribinos a: <a href="mailto:hi@scoutaction.com">hi@scoutaction.com</a>',
      titleItem: 'Acerca del startup',
      titlePerson: 'Fundador responsable del startup',
      titleOtherInfo: 'Otra información',
      errorMsg: '',
      successMsg: 'Tu formulario fue guardado con éxito y una confirmación se envió a tu email.',
      nextStep: '<p>El organizador de la convocatoria recibirá todos los formularios enviados cuando termine la búsqueda y contactará a aquellos participantes que cumplan con sus criterios preestablecidos.</p><p>No dejes de volver a visitar Scout Action pronto para ver otras convocatorias abiertas.</p>',
      resultTitle: function(result) { return result.title },
      resultDescription: function(result) { return result.description },
      minPoints: 100,
      props: {
        title: {
          value: '',
          max: 100,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,100}$',
          required: true,
          text: 'Título', // ctx.$t('newItemTitle'),
          hint: 'Título' // ctx.$t('newItemTitleHint')
        },
        description: {
          value: '',
          max: 3000,
          valid: '^[À-ÿ\\w\\s\\S ]{1,3000}$',
          required: true,
          text: 'Descripción', // ctx.$t('newItemDescription'),
          hint: 'Descripción' // ctx.$t('newItemDescriptionHint')
        },
        location: {
          value: '',
          max: 100,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,100}$',
          required: true,
          text: 'Ubicación geográfica', // ctx.$t('newItemLocation'),
          hint: 'Localidad, provincia' // ctx.$t('newItemLocationHint')
        },
        itemLink: {
          value: '',
          max: 100,
          valid: '((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
          required: false,
          text: 'Link al website o red social (si tiene)', // ctx.$t('newItemLink'),
          hint: 'Ejemplo: https://www.ejemplo.com'
        },
        etags: {
          value: [],
          max: 25,
          valid: '.*',
          testArray: true,
          testProp: 'etagsSel',
          required: false,
          text: 'Categorías', // ctx.$t('newItemCategories'),
          placeholder: 'Categorías disponibles',
          hint: 'Seleccioná una o más categorías' // ctx.$t('newItemCategoriesHint')
        },
        itemOptions: {
          value: [],
          valid: '.+',
          testArray: false,
          testProp: 'itemOption',
          required: true,
          text: 'Etapa en la que se encuentra', // ctx.$t('newItemOptions'),
          hint: 'Seleccioná la etapa actual del startup' // ctx.$t('newItemOptionsHint')
        },
        files: {
          value: [],
          valid: '.+',
          testArray: true,
          required: false,
          text: 'Imágenes relevantes del startup', // ctx.$t('newItemImages'),
          placeholder: 'Haz click aquí o arrastra imágenes a este área para seleccionarlas.',
          hint: 'Subir hasta 5 imágenes', // ctx.$t('newItemImagesHint'),
          maxFiles: 5,
          acceptedFiles: 'image/*'
        },
        firstname: {
          value: '',
          max: 32,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,32}$',
          required: true,
          text: 'Nombre', // ctx.$t('newItemFirstName'),
          hint: 'Ingrese su nombre' // ctx.$t('newItemFirstNameHint')
        },
        lastname: {
          value: '',
          max: 32,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,32}$',
          required: true,
          text: 'Apellido', // ctx.$t('newItemLastName'),
          hint: 'Ingrese su apellido' // ctx.$t('newItemLastNameHint')
        },
        email: {
          value: '',
          max: 32,
          valid: '[a-z0-9]+@[a-z]+\\.[a-z]{2,3}',
          required: true,
          text: 'Email', // ctx.$t('newItemEmail'),
          hint: 'Email para recibir notificaciones' // ctx.$t('newItemEmailHint')
        },
        personLink: {
          value: '',
          max: 130,
          valid: '((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
          required: false,
          text: 'Link al website o red social del fundador responsable (si tiene)', // ctx.$t('newItemPersonLink'),
          hint: 'Ejemplo: https://www.ejemplo.com/perfil'
        },
        shareItem: {
          value: false,
          required: false,
          text: 'Si, compartir información', // ctx.$t('newItemYesShareOption'),
          hint: 'Otros inversores y organizaciones que tienen sus propias convocatorias podrían estar interesados. ¿Te gustaría que compartamos tu startup con ellos también?' // ctx.$t('newItemShareInfoExplanation')
        }
      }
    },
    'talent-es.v1': {
      lang: 'es',
      title: '',
      instructions: '',
      titleItem: 'Nuevo envío de CV',
      titlePerson: 'Datos del postulante',
      // titleOtherInfo: '',
      errorMsg: '',
      successMsg: 'Tu CV fue guardado con éxito y una confirmación se envió a tu email.',
      nextStep: '',
      resultTitle: function(result) { return result.firstname + ' ' + result.lastname },
      resultDescription: function() { return '' },
      minPoints: 20,
      props: {
        files: {
          value: [],
          valid: '.+',
          testArray: true,
          required: true,
          text: 'Curriculum en formato PDF',
          placeholder: 'Hacé click aquí o arrastra el archivo PDF a este área para seleccionarlo.',
          hint: 'Subir el CV en formato PDF',
          maxFiles: 1,
          acceptedFiles: 'application/pdf'
        },
        firstname: {
          value: '',
          max: 32,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,32}$',
          required: true,
          text: 'Nombre', // ctx.$t('newItemFirstName'),
          hint: 'Ingrese su nombre' // ctx.$t('newItemFirstNameHint')
        },
        lastname: {
          value: '',
          max: 32,
          valid: '^[À-ÿ\\w\\d,;\\- ]{1,32}$',
          required: true,
          text: 'Apellido', // ctx.$t('newItemLastName'),
          hint: 'Ingrese su apellido' // ctx.$t('newItemLastNameHint')
        },
        email: {
          value: '',
          max: 32,
          valid: '[a-z0-9]+@[a-z]+\\.[a-z]{2,3}',
          required: true,
          text: 'Email', // ctx.$t('newItemEmail'),
          hint: 'Email para recibir notificaciones' // ctx.$t('newItemEmailHint')
        },
      }
    }
  }
}