<template>
  <div class="columns">
    <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
      <SiteNav :user="user" />
      <div class="upload-wrapper">
        <div id="warning-box" class="text-center warningBox" v-if="mission.participated" data-test="warning-box" v-html="$t('NewItemWarningAlreadyParticipated')">
        </div>
        <div v-if="mission.media0" class="float-right">
          <img class="card-image ml-2" v-lazy="thumbPath + mission.media0" style="width: 140px;">
        </div>
        <h4>{{mission.title}}</h4>
        <div style="font-size: .7rem" v-html="fBP.instructions"></div>
        <div class="field-separator"></div>
        <h6 v-if="fBP.titleItem">{{fBP.titleItem}}</h6>
        <div v-if="fBP.props.title" class="field-area">
          <div class="form-group" :class="[fieldFocus['title'] ? (fieldIsOk('title') ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="title">{{fBP.props.title.text}} <span v-if="this.fBP.props['title'].required">*</span></label>
            <small v-if="fBP.props.title.hint" class="d-block text-dark mb-2">{{fBP.props.title.hint}}</small>
            <input id="title" class="form-input" v-model="fBP.props.title.value" type="text" @keyup="updateLen('title')" @focus="fieldFocus['title']=true">
            <div class="d-inline-block float-right">
              <div class="socialPreview" v-html="counterText.title"></div>
            </div>
          </div>
        </div>
        <div v-if="fBP.props.description" class="field-area">
          <div class="form-group" :class="[fieldFocus['description'] ? (fieldIsOk('description') ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="description">{{fBP.props.description.text}} <span v-if="this.fBP.props['description'].required">*</span></label>
            <small v-if="fBP.props.description.hint" class="d-block text-dark mb-2">{{fBP.props.description.hint}}</small>
            <textarea id="description" class="form-input" v-model="fBP.props.description.value" @keyup="updateLen('description')" rows="5" @focus="fieldFocus['description']=true"></textarea>
            <div class="d-inline-block float-right">
              <div class="socialPreview" v-html="counterText.description"></div>
            </div>
          </div>
        </div>
        <div v-if="fBP.props.location" class="field-area">
          <div class="form-group" :class="[fieldFocus['location'] ? (fieldIsOk('location') ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="location">{{fBP.props.location.text}} <span v-if="this.fBP.props['location'].required">*</span></label>
            <small v-if="fBP.props.location.hint" class="d-block text-dark mb-2">{{fBP.props.location.hint}}</small>
            <input id="location" class="form-input" v-model="fBP.props.location.value" type="text" @keyup="updateLen('location')" @focus="fieldFocus['location']=true">
            <div class="d-inline-block float-right">
              <div class="socialPreview" v-html="counterText.location"></div>
            </div>
          </div>
        </div>
        <div v-if="fBP.props.itemLink" class="field-area">
          <div class="form-group" :class="[fieldFocus['itemLink'] ? (fieldIsOk('itemLink') ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="itemLink">{{fBP.props.itemLink.text}} <span v-if="this.fBP.props['itemLink'].required">*</span></label>
            <small v-if="fBP.props.itemLink.hint" class="d-block text-dark mb-2">{{fBP.props.itemLink.hint}}</small>
            <input id="itemLink" class="form-input" v-model="fBP.props.itemLink.value" type="text" @focus="fieldFocus['itemLink']=true">
          </div>
        </div>
        <div v-if="fBP.props.etags" class="field-area">
          <div class="form-group" :class="[fieldFocus['etags'] ? (fieldIsOk('etags') ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="etags">{{fBP.props.etags.text}} <span v-if="this.fBP.props['etags'].required">*</span></label>
            <small v-if="fBP.props.etags.hint" class="d-block text-dark mb-2">{{fBP.props.etags.hint}}</small>
            <multiselect id="etags" v-model="etagsSel" tag-placeholder="Add this as new label" :placeholder="fBP.props.etags.placeholder" label="name" track-by="code" :options="mission.etags" :multiple="true" :taggable="false" @tag="addETag" @focus="fieldFocus['etags']=true">
            </multiselect>
          </div>
        </div>
        <div v-if="fBP.props.itemOptions && mission.itemOptions.length" class="field-area">
          <div class="form-group" :class="[fieldFocus['itemOptions'] ? (fieldIsOk('itemOptions') ? 'has-success' : 'has-error') : '']">
            <label class="form-label" for="itemOptions">{{fBP.props.itemOptions.text}} <span v-if="this.fBP.props['itemOptions'].required">*</span></label>
            <small v-if="fBP.props.itemOptions.hint" class="d-block text-dark mb-2">{{fBP.props.itemOptions.hint}}</small>
            <label class="form-radio form-inline" v-bind:key="opt.code" v-for="opt in mission.itemOptions">
              <input type="radio" class="form-input" v-model="itemOption" name="itemOptions" :value="opt.code" @focus="fieldFocus['itemOptions']=true"><i class="form-icon"></i> {{opt.name}}
            </label>
          </div>
        </div>
        <div v-if="fBP.props.files" class="form-group" style="margin-bottom: 1rem" :class="[fieldFocus['files'] ? (fieldIsOk('files') ? 'has-success' : 'has-error') : '']">
          <label class="form-label">{{fBP.props.files.text}} <span v-if="this.fBP.props['files'].required">*</span></label>
          <small v-if="fBP.props.files.hint" class="d-block text-dark mb-2">{{fBP.props.files.hint}}</small>
          <div ref="myVueDropzone" class="dropzone dropbox" id="sa-dropzone"></div>
        </div>
        <div v-if="fBP.titlePerson">
          <br>
          <br>
          <h6>{{fBP.titlePerson}}</h6>
          <div v-if="fBP.props.firstname && fBP.props.lastname" class="field-area">
            <div class="form-inline mr-2" :class="[fieldFocus['firstname'] ? (fieldIsOk('firstname') ? 'has-success' : 'has-error') : '']">
              <label class="form-label" for="firstname">{{fBP.props.firstname.text}} <span v-if="this.fBP.props['firstname'].required">*</span></label>
              <small v-if="fBP.props.firstname.hint" class="d-block text-dark mb-2">{{fBP.props.firstname.hint}}</small>
              <input id="firstname" class="form-input" v-model="fBP.props.firstname.value" type="text" @keyup="updateLen('firstname')" @focus="fieldFocus['firstname']=true">
            </div>
            <div class="form-inline" :class="[fieldFocus['lastname'] ? (fieldIsOk('lastname') ? 'has-success' : 'has-error') : '']">
              <label class="form-label" for="lastname">{{fBP.props.lastname.text}} <span v-if="this.fBP.props['lastname'].required">*</span></label>
              <small v-if="fBP.props.lastname.hint" class="d-block text-dark mb-2">{{fBP.props.lastname.hint}}</small>
              <input id="lastname" class="form-input" v-model="fBP.props.lastname.value" type="text" @keyup="updateLen('lastname')" @focus="fieldFocus['lastname']=true">
            </div>
          </div>
          <div v-if="fBP.props.email" class="field-area">
            <div class="form-group" :class="[fieldFocus['email'] ? (fieldIsOk('email') ? 'has-success' : 'has-error') : '']">
              <label class="form-label" for="email">{{fBP.props.email.text}} <span v-if="this.fBP.props['email'].required">*</span></label>
              <small v-if="fBP.props.email.hint" class="d-block text-dark mb-2">{{fBP.props.email.hint}}</small>
              <input id="email" class="form-input" v-model="fBP.props.email.value" type="text" @focus="fieldFocus['email']=true">
            </div>
          </div>
          <div v-if="fBP.props.personLink" class="field-area">
            <div class="form-group" :class="[fieldFocus['personLink'] ? (fieldIsOk('personLink') ? 'has-success' : 'has-error') : '']">
              <label class="form-label" for="personLink">{{fBP.props.personLink.text}} <span v-if="this.fBP.props['personLink'].required">*</span></label>
              <small v-if="fBP.props.personLink.hint" class="d-block text-dark mb-2">{{fBP.props.personLink.hint}}</small>
              <input id="personLink" class="form-input" v-model="fBP.props.personLink.value" type="text" @focus="fieldFocus['personLink']=true">
            </div>
          </div>
          <br>
          <br>
        </div>
        <div v-if="fBP.titleOtherInfo">
          <h6>{{fBP.titleOtherInfo}}</h6>
          <div v-if="fBP.props.shareItem" class="field-area">
            <div class="form-group" :class="[fieldFocus['shareItem'] ? (fieldIsOk('shareItem') ? 'has-success' : 'has-error') : '']">
              <label class="form-switch" for="shareItem">
                <input type="checkbox" v-model="fBP.props.shareItem.value" id="shareItem">
                <i class="form-icon"></i> {{fBP.props.shareItem.text}} <span v-if="this.fBP.props['shareItem'].required">*</span>
              </label>
              <small v-if="fBP.props.shareItem.hint" class="d-block text-dark mb-2">{{fBP.props.shareItem.hint}}</small>
            </div>
          </div>
        </div>
        <!-- <div class="field-area">
          <div class="form-group">
            <label class="form-label" for="scoutRefId">{{$t('newItemScoutReferral')}}</label>
            <input id="scoutRefId" v-model="scoutRefId" type="text" style="width: 130px;" disabled="disableScoutRefId">
          </div>
        </div> -->
        <div id="loading" :class="[isProcessing ? '': 'hide']">
          <progress class="progress" max="100"></progress>
        </div>
        <div style="width: 100%; margin-top: 0rem" class="text-center">
          <router-link class="btn btn-secondary mr-2" :to="'/dash'">Cancelar</router-link>
          <button id="send-btn" class="btn btn-primary" v-on:click="readyUpload" :disabled="(mission.participated || isProcessing)">{{$t('Send')}}</button>
        </div>
      </div>
      <div id="bottom-space"></div>
    </div>
  </div>
</template>
<script>
import emitter from '@/lib/general/emitter'
const nestedProperty = require('nested-property')
// import { app } from '@/main'
// import { getUser } from '@/services/auth'
import SiteNav from '@/components/SiteNav.vue'
import { upload } from '@/services/upload'
import { newPost } from '@/services/new-post.service'
import { Dropzone } from 'dropzone'
import Multiselect from 'vue-multiselect'
import postAsync from '@/services/post-async'
const templates = require(`@/lib/mission/templates.js`)()
import handleError from '@/services/handle-error'
let dropzone
Dropzone.autoDiscover = false // prevents 'Dropzone already attached' error
const initData = {
  fBP: {
    title: '',
    props: {}
  },
  mission: {
    title: '',
    etags: [],
    itemOptions: [],
    media0: '',
    participated: false
  },
  loaded: false
}

async function loadMeta(missionId) {
  const payload = {
    missionId: missionId
  }
  const res = await postAsync(payload, 'mission/meta')
  if (!res || !res.data) {
    throw new Error('Mission not found')
  }
  if (!nestedProperty.get(res, 'data.formId')) {
    throw new Error('Form Id not provided!')
  }
  let fBP
  if (nestedProperty.get(res, 'data.formId') && nestedProperty.get(res, 'data.formId') !== 'internal') {
    fBP = templates[res.data.formId]
  }
  let mission = {
    title: nestedProperty.get(res, 'data.title'),
    itemOptions: nestedProperty.get(res, 'data.itemOptions'),
    etags: nestedProperty.get(res, 'data.etags'),
    media0: nestedProperty.get(res, 'data.media0'),
    participated: nestedProperty.get(res, 'data.participated')
  }
  return { mission, fBP }
}

export default {
  name: 'NewItem',
  title: function() {
    return `Scout Action :: ${this.$t('newEntry')}`
  },
  components: {
    SiteNav,
    Multiselect
  },
  props: {
    user: Object
  },
  data: function() {
    return {
      ...initData,
      scoutId: '',
      missionId: null,
      scoutRefId: localStorage.getItem('scoutRefId'),
      disableScoutRefId: false,
      etagsSel: [],
      itemOption: null,
      thumbPath: process.env.VUE_APP_THUMB_PATH,
      fieldFocus: {},
      counterText: {},
      isProcessing: false
    }
  },

  async beforeRouteEnter(to, from, next) {
    emitter.emit('loading', 'loading')
    try {
      const { mission, fBP } = await loadMeta(to.params.id)
      initData.fBP = fBP
      initData.mission = mission
      initData.loaded = true
      emitter.emit('loading', '')
    } catch (error) {
      handleError(error)
    } finally {
      next()
    }
  },

  created() {
    document.body.className = 'auth-zone'
    const scoutRefId = localStorage.getItem('scoutRefId') ? localStorage.getItem('scoutRefId').trim() : ''
    this.disableScoutRefId = scoutRefId !== ''
    this.missionId = this.$route.params.id
  },

  async mounted() {
    const self = this
    if (!this.loaded) {
      try {
        const { mission, fBP } = await loadMeta(this.missionId)
        this.fBP = fBP
        this.mission = mission
        this.loaded = true
      } catch (error) {
        return handleError(error)
      }
    }
    this.scoutId = this.user.scoutId
    if (this.fBP.props.email) {
      this.fBP.props.email.value = this.user.email || ''
    }
    if (this.fBP.props.firstname) {
      this.fBP.props.firstname.value = this.user.firstname || ''
    }
    if (this.fBP.props.lastname) {
      this.fBP.props.lastname.value = this.user.lastname || ''
    }
    if (document.getElementById('sa-dropzone') && this.fBP.props.files) {
      const options = {
        url: '/',
        autoProcessQueue: false,
        autoQueue: false,
        addRemoveLinks: true,
        clickable: true,
        maxFiles: this.fBP.props.files.maxFiles,
        acceptedFiles: this.fBP.props.files.acceptedFiles,
      }
      dropzone = new Dropzone('div#sa-dropzone', options)
      const btn = document.querySelector('.dz-button')
      if (btn) {
        btn.innerText = self.fBP.props.files.placeholder
      }
      dropzone.on('addedfile', function(file) {
        // console.log(JSON.stringify(file), file.accepted, file.upload.filename)
        if (file.upload.filename) {
          self.fBP.props.files.value.push(file.upload.filename)
          self.fieldIsOk('files')
          // console.log(self.files)
        }
        file.previewElement.addEventListener('click', function() {
          dropzone.removeFile(file)
        })
      })
    }
    for (let k in this.fBP.props) {
      this.updateLen(k)
    }
  },

  methods: {
    fieldIsOk: function(field) {

      // no rule to validate? It's ok then!
      if (!this.fBP.props[field].valid) {
        return true
      }

      // validation regex
      const regex = new RegExp(this.fBP.props[field].valid)

      // test on the very field or a different one?
      const testedProp = this.fBP.props[field].testProp ? this[this.fBP.props[field].testProp] : this.fBP.props[field].value

      // if test is on an array, we do this
      if (this.fBP.props[field].testArray) {
        let passed = false
        testedProp.map(val => {
          // we test some object arrays that have a 'code' prop
          let testedVal = Object.prototype.hasOwnProperty.call(val, 'code') ? val.code : val
          passed = testedVal.match(regex) ? true : false
        })
        return passed
      } else {
        if (testedProp === null) {
          return false
        }
        return testedProp.match(regex) || (!this.fBP.props[field].required && testedProp === '')
      }
    },

    updateLen: function(field) {
      if (!this.fBP.props[field].max) {
        return false
      }
      this.counterText[field] = `${this.fBP.props[field].value.length} / ${this.fBP.props[field].max}`
    },

    // only helps if we allow the user to add new tags
    addETag(newTag) {
      const tag = {
        name: newTag.substring(0, this.fBP.props.etags.max),
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.mission.etags.push(tag)
      this.etagsSel.push(tag)
    },

    readyUpload() {
      let that = this
      for (let k in this.fBP.props) {
        if (!this.fieldIsOk(k) && this.fBP.props[k].required) {
          that.fieldFocus[k] = true
          alert('Por favor completa todos los campos requeridos!')
          return false
        }
      }

      let payload = {
        missionId: this.missionId,
        scoutRefId: this.scoutRefId ? this.scoutRefId.trim() : '',
        scoutId: this.scoutId,
        email: this.fBP.props.email ? this.fBP.props.email : this.user.email
      }

      for (let k in this.fBP.props) {
        if (typeof this.fBP.props[k].value === 'string' || this.fBP.props[k].value instanceof String) {
          payload[k] = this.fBP.props[k].value.trim()
        }
      }

      if (this.fBP.props.shareItem) {
        payload['shareItem'] = this.fBP.props['shareItem'].value
      }

      if (this.fBP.props.itemOptions) {
        payload['itemOption'] = this.itemOption
      }

      if (this.fBP.props.etags) {
        let tags = []
        if (this.etagsSel) {
          this.etagsSel.map(function(x) {
            tags.push(x.name.substring(0, that.fBP.props.etags.max))
          })
        }
        payload['etags'] = tags
      }

      this.isProcessing = true
      let files = dropzone.getAcceptedFiles() // make sure file names are in lowercase

      let postWithAttachments = function() {
        return files.reduce(function(p, file) {
          return p.then(function(_) {
            return upload(file, that.missionId)
              .catch((err) => {
                console.log(err)
              })
              .then((id) => {
                _.push(id)
                if (_.length === files.length) {
                  //console.log('submitted', _)
                  console.log(that.scoutRefId, '>>', that.scoutId)
                  payload.media = _ // prop 'media' only added if there are attachments
                  return newPost(payload)
                    .catch((err) => {
                      console.log(err)
                    })
                    .then(() => {
                      that.isProcessing = false
                      localStorage.removeItem('scoutRefId')
                      that.$router.replace('/success-item')
                    })
                }
                return _
              });
          });
        }, Promise.resolve([]))
      }

      let postWithoutAttachments = function() {
        return newPost(payload)
          .catch((err) => {
            console.log(err)
          })
          .then(() => {
            console.log(that.scoutRefId, '>>', that.scoutId)
            that.isProcessing = false
            localStorage.removeItem('scoutRefId')
            that.$router.replace('/success-item')
          })
      }

      if (!files.length) {
        postWithoutAttachments()
      } else {
        postWithAttachments()
      }
    }
  }
}
</script>
<style src="@/assets/vue-multiselect.css"></style>
<style>
input,
textarea {
  width: 100%;
}

.dropzone .dz-preview .dz-progress {
  z-index: 1 !important;
}

.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone,
.dz-button {
  padding: 1rem !important;
  font-size: 0.7rem !important;
}

.multiselect {
  /*margin-bottom: 1.3rem;*/
}

.multiselect .multiselect__tags {}

.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: #407BFF !important;
  outline: none;
  color: #fff;
}

.multiselect__tag {
  background: #407BFF !important;
}

.multiselect__tags {
  border: 1px solid #ddd;
}

/*
.sa-tag {
  background: #407BFF !important;
}
*/
.form-checkbox input:checked+.form-icon,
.form-radio input:checked+.form-icon,
.form-switch input:checked+.form-icon {
  background: #407BFF;
  border-color: #407BFF;
}
</style>
<style scoped>
.socialPreview {
  font-size: 0.65rem;
  line-height: 0.7rem;
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
  background-color: #eee;
}

h6 {
  margin-bottom: 2rem !important;
}

input,
textarea {
  border: 1px solid #ddd;
}

.helper-text {
  color: #666;
  font-size: 95%;
}

.input-field {
  margin-bottom: 4.0rem;
}

.upload-wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #E4E6E7;
}

.upload-wrapper h4 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.field-separator {
  height: 2rem;
}
</style>