<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth text-center">
          <a @click="$router.push({name: 'HomePage', params: {lang: lang}})" class="navbar-brand" style="margin-top: 2rem; display: block;">Scout Action</a>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column col-md-8 col-lg-4 col-xl-3 col-2 p-centered">
          <div class="section-spacer"></div>
          <!-- <div class="column col-lg-12 col-6 p-centered"> -->
          <div v-if="initial" class="text-center">
            <p>{{$t('resetPwdEnterEmail')}}</p>
          </div>
          <div v-if="checkEmail" class="text-center">
            <p>{{$t('resetPwdCheckEmail')}}</p>
          </div>
          <div v-if="askForCode" class="text-center">
            <p>{{$t('resetPwdInstructions')}}</p>
          </div>
          <div class="text-center">
            <div class="label label-error mb-2" v-if="error !== null">{{ error }}</div>
            <form v-if="initial" @submit.prevent="reset">
              <div class="form-group">
                <input id="email" type="text" class="form-input" v-model="email" :placeholder="$t('Email')" required>
              </div>
              <div class="center-align">
                <button class="btn btn-primary btn-large col-12" :class="[isWorking ? 'disabled loading' : '']"><span v-show="!isWorking">{{$t('resetPwdReset')}}</span></button>
                <div class="mt-2"></div>
                <p>
                  <small>
                    <!-- <router-link :to="({name: 'AboutPage', params: {lang: lang}})">{{$t('Help')}}</router-link> -->
                    <router-link :to="({name: 'HomePage', params: {lang: lang}})">{{$t('Home')}}</router-link>
                    &middot;
                    <router-link :to="({name: 'LoginPage', params: {lang: lang}})">{{$t('LogIn')}}</router-link>
                  </small>
                </p>
              </div>
            </form>
            <form v-if="askForCode" @submit.prevent="verifyCode">
              <div class="form-group">
                <input id="email" type="text" class="form-input" v-model="email" :placeholder="$t('Email')" disabled="disabled">
              </div>
              <div class="form-group">
                <input id="resetCode" type="text" class="form-input" v-model="resetCode" placeholder="confirmation code" autocomplete="off" required disabled="disabled">
              </div>
              <div class="form-group">
                <input id="password" type="password" class="form-input" v-model="pass" :placeholder="$t('resetNewPassword')" autocomplete="off" required>
              </div>
              <div class="center-align">
                <button class="btn btn-primary btn-large col-12" :class="{loading:isWorking}">{{$t('resetPwdSaveNewPwd')}}</button>
                <div class="mt-2"></div>
                <p>
                  <small>
                    <router-link :to="({name: 'HomePage', params: {lang: lang}})">{{$t('Home')}}</router-link>
                    &middot;
                    <router-link :to="({name: 'LoginPage', params: {lang: lang}})">{{$t('LogIn')}}</router-link>
                    <!-- <router-link :to="({name: 'AboutPage', params: {lang: lang}})">{{$t('Help')}}</router-link> -->
                  </small>
                </p>
              </div>
            </form>
          </div>
          <!-- </div> -->
          <!-- </div> -->
          <div class="section-spacer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from '@/lib/general/emitter'
const nestedProperty = require('nested-property')
// import { app } from '@/main'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n-lite'
import postAsync from '@/services/post-async'
export default {
  name: 'ResetPage',
  title: function() {
    return `Scout Action :: ${this.$t('resetPwdTitle')}`
  },
  props: {
    resetEmail: {
      type: String
    },
    resetCodeProp: {
      type: String
    }
  },
  setup() {
    const route = useRoute()
    const lang = route.params ? route.params.lang : 'en'
    return { lang }
  },
  data: function() {
    return {
      i18n: useI18n(),
      currentLocale: 'en',
      email: this.resetEmail ? this.resetEmail : '',
      resetCode: this.resetCodeProp,
      pass: '',
      loggedIn: false,
      error: null,
      isWorking: false,
      initial: true,
      askForCode: false,
      checkEmail: false
    }
  },
  mounted: function() {
    const { current } = this.i18n
    this.currentLocale = current || 'en'
    if (!this.resetEmail || !this.resetCode) {
      return false
    }
    this.initial = false
    this.askForCode = true
  },
  methods: {
    async reset() {
      const self = this
      this.initial = false
      this.isWorking = true
      emitter.emit('loading', 'loading')
      const payload = {
        email: this.email,
        lang: this.currentLocale
      }
      const response = await postAsync(payload, 'user/reset').catch(error => {
        const message = nestedProperty.get(error, 'response.data.message')
        self.error = self.$t(message ? message : 'SomethingWentWrong')
        emitter.emit('loading', '')
        return false
      })
      if (response.status === 200) {
        self.isWorking = false
        self.checkEmail = true
        emitter.emit('loading', '')
      }
    },
    async verifyCode() {
      const self = this
      self.isWorking = true
      emitter.emit('loading', 'loading')
      const payload = {
        email: this.email,
        password: this.pass,
        code: this.resetCode
      }
      const response = await postAsync(payload, 'user/change').catch(error => {
        const message = nestedProperty.get(error, 'response.data.message')
        self.error = self.$t(message ? message : 'SomethingWentWrong')
        self.isWorking = false
        emitter.emit('loading', '')
        return false
      })
      if (response.status === 200) {
        self.error = ''
        emitter.emit('loading', '')
        alert(self.$t('resetPwdSuccess'))
        self.$router.replace('/login')
      }
    }
  }
}
</script>