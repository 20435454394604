<template>
  <div>
    <WebNav />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="empty">
            <p class="empty-title h1">{{code}}</p>
            <p class="empty-subtitle">{{message}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WebNav from '@/components/WebNav.vue'
export default {
  name: 'ErrorPage',
  props: {
    message: {
      type: String
    },
    code: {
      type: String
    }
  },
  components: {
    WebNav
  },
  data: function() {
    return {}
  },
  mounted: function() {}
}
</script>