import * as axios from 'axios'
import { getJwtToken } from '@/services/auth'

function newMission(payload) {
  return new Promise(function(fulfilled, rejected) {
    axios.defaults.headers.common['Authorization'] = getJwtToken()
    return axios({
        method: 'post',
        url: process.env.VUE_APP_EXEC_API + '/saveMission',
        data: payload
      })
      .then(x => {
        fulfilled(x.data.id)
      })
      .catch((err) => {
        rejected(err)
      })
  })
}

export {
  newMission
}