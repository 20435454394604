import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import routes from './routes'

function scrollBehavior(to) {
  return to.hash ? { selector: to.hash } : { left: 0, top: 0 }
}

const router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior: scrollBehavior,
  routes
})

// https://stackoverflow.com/questions/67889690/vue-3-accessing-vue-instance-in-vue-router-outside-a-vue-file
export default {
  install(app, options) {
    router.install(app)
    // const nowhere = () => new Promise(() => {})
    router.beforeEach((to, from, next) => {
      let navLang = navigator.language || navigator.userLanguage
      let userLang = localStorage.getItem('lang') || ''
      let lang = userLang ? userLang : navLang.substring(0, 2)
      if (['es', 'en'].indexOf(lang) === -1) {
        lang = 'en'
      }
      to.params.lang = lang
      options.i18n.changeLocale(lang)
      next()
    })
    trackRouter(router)
  }
}