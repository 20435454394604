import * as axios from 'axios'
import { getJwtToken } from '@/services/auth'
function postService(payload, path) {
  return new Promise(function(resolve, reject) {
    axios.defaults.headers.common.Authorization = getJwtToken()
    const params = {
      method: 'post',
      url: process.env.VUE_APP_EXEC_API + '/' + path,
      data: payload
    }
    return axios(params)
      .then(x => {
        // console.log('success', x)
        resolve(x)
      })
      .catch((err) => {
        // console.log(err)
        reject(err)
      })
  })
}

export {
  postService
}