import moment from 'moment'
import postAsync from '@/services/post-async'
const nestedProperty = require('nested-property')

async function loadMission(missionId) {
  if (!missionId) {
    return {}
  }
  const response = await postAsync({ missionId: missionId }, 'mission/get')
  let mission = nestedProperty.get(response, 'data.mission') || {}
  mission.expDate = mission.expDate ? moment(mission.expDate).format() : ''
  mission.strEtags = mission.etags ? mission.etags.join(', ') : ''
  mission.strOptions = mission.itemOptions ? mission.itemOptions.join('; ') : ''
  return mission
}

export {
  loadMission
}