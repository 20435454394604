<template>
  <div>
    <div class="columns">
      <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
        <SiteNav :user="user" />
        <div class="card browse-card mb-2">
          <div class="card-body">
            <p class="float-right">
              <router-link :to="({name: 'HelpPage', params: {lang: user.lang}})" target="_blank">{{$t('Help')}}</router-link>
            </p>
            <h4>{{$t('profilePageTitle')}}: {{localeValue(totalPts)}} {{$t('Points')}}</h4>
            <p><b>Username:</b> {{user.username}}</p>
            <p><b>Scout ID:</b> {{user.scoutId}}</p>
            <p><b>{{$t('profilePageTotalScore')}}:</b> {{localeValue(totalPts)}} {{$t('Points')}} <br class="show-sm">({{$t('Equivalent')}} USDT {{equivalentValue(totalPts)}})</p>
            <div class="section-spacer"></div>
            <h5>{{$t('Redeem')}}</h5>
            <div class="divider"></div>
            <div class="columns">
              <div class="column col-lg-6 col-md-12">
                <table style="border: 0;">
                  <tr>
                    <td><img class="mr-2" src="/img/yenny.png" style="width: 100px;"></td>
                    <td>
                      <p><b>Gift card <a href="https://www.yenny-elateneo.com/" target="_blank">Yenny</a></b><br>
                        150 {{$t('Points')}} / 1000 Pesos<br><button class="btn btn-bold btn-sm mt-2" @click="redeem('GIFTCARD')" :disabled="disableRedeem.GIFTCARD || disableAllRedeem">{{$t('Redeem')}}</button>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="column col-lg-6 col-md-12">
                <table style="border: 0;">
                  <tr>
                    <td><img class="mr-2" src="/img/usdt.png" style="width: 100px;"></td>
                    <td>
                      <p><b><a href="https://www.coinbase.com/price/tether" target="_blank">USDT crypto</a></b><br>
                        500 {{$t('Points')}} / 25 USDT<br><button class="btn btn-bold btn-sm mt-2" @click="redeem('CRYPTO')" :disabled="disableRedeem.CRYPTO || disableAllRedeem">{{$t('Redeem')}}</button>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="section-spacer"></div>
            <h5>{{$t('profilePagePreferences')}}</h5>
            <div class="divider"></div>
            <div class="form-group">
              <label class="form-switch">
                <input type="checkbox" v-model="mailing" @change="toggleMailing()">
                <i class="form-icon"></i> <span>{{$t('profilePageSendEmails')}}</span>
              </label>
            </div>
            <div class="section-spacer"></div>
            <h5>{{$t('profilePageMailingAddress')}}</h5>
            <div class="divider"></div>
            <div class="form-group" style="width: 100%">
              <div class="form-inline mr-2" style="width: 49%">
                <label class="form-label" for="firstname">{{$t('profilePageFirstname')}}</label>
                <input class="form-input" type="text" id="firstname" v-model="firstname" :placeholder="$t('profilePageFirstname')" @blur="saveProp('firstname')" @keyup.enter="saveProp('firstname')">
              </div>
              <div class="form-inline" style="width: 49%">
                <label class="form-label" for="lastname">{{$t('profilePageLastname')}}</label>
                <input class="form-input" type="text" id="lastname" v-model="lastname" :placeholder="$t('profilePageLastname')" @blur="saveProp('lastname')" @keyup.enter="saveProp('lastname')">
              </div>
            </div>
            <div class="form-group" style="width: 100%">
              <label class="form-label" for="street">{{$t('profilePageStreetAndNumber')}}</label>
              <input class="form-input" type="text" id="street" v-model="street" :placeholder="$t('profilePageStreetAndNumber')" @blur="saveProp('street')" @keyup.enter="saveProp('street')">
            </div>
            <div class="form-group" style="width: 100%">
              <div class="form-inline mr-2" style="width: 49%">
                <label class="form-label" for="city">{{$t('profilePageCity')}}</label>
                <input class="form-input" type="text" id="city" v-model="city" :placeholder="$t('profilePageCity')" @blur="saveProp('city')" @keyup.enter="saveProp('city')">
              </div>
              <div class="form-inline" style="width: 49%">
                <label class="form-label" for="state">{{$t('profilePageState')}}</label>
                <input class="form-input" type="text" id="state" v-model="state" :placeholder="$t('profilePageState')" @blur="saveProp('state')" @keyup.enter="saveProp('state')">
              </div>
            </div>
            <div class="form-group" style="width: 100%">
              <div class="form-inline mr-2" style="width: 49%">
                <label class="form-label" for="zipcode">{{$t('profilePageZipCode')}}</label>
                <input class="form-input" type="text" id="zipcode" v-model="zipcode" :placeholder="$t('profilePageZipCode')" @blur="saveProp('zipcode')" @keyup.enter="saveProp('zipcode')">
              </div>
              <div class="form-inline" style="width: 49%">
                <label class="form-label" for="country">{{$t('profilePageCountry')}}</label>
                <input class="form-input" type="text" id="country" v-model="country" :placeholder="$t('profilePageCountry')" @blur="saveProp('country')" @keyup.enter="saveProp('country')">
              </div>
            </div>
            <div class="section-spacer"></div>
            <h5>{{$t('profileYourActivity')}}</h5>
            <div class="divider"></div>
            <div v-if="sortedTransactions.length===0">
              <p>{{$t('profilePageNoActivity')}}</p>
            </div>
            <div v-else>
              <table style="font-size: 0.7rem; width: 100%;">
                <thead>
                  <th class="text-left">{{$t('DateTime')}}</th>
                  <th class="text-left">{{$t('Activity')}}</th>
                  <th>{{$t('Points')}}</th>
                </thead>
                <tr v-bind:key="transaction.createdAt" v-for="transaction of sortedTransactions">
                  <td valign="top" style="min-width: 100px;">{{transaction.dateTime}}</td>
                  <td valign="top">
                    {{transaction.activity}}
                    <span v-if="transaction.flag === 'PENDING'" class="text-gray"><br><i>Pendiente</i></span>
                    <span v-if="transaction.flag === 'VOIDED'" class="text-gray"><br><i>Anulada</i></span>
                    <span v-if="transaction.notes" class="text-gray"><br>{{transaction.notes}}</span>
                  </td>
                  <td class="text-center" valign="top" :style="[transaction.flag ==='VOIDED' ? 'text-decoration: line-through' : '']">{{transaction.points}}</td>
                </tr>
              </table>
            </div>
            <div class="section-spacer"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal-sm" :class="[showShare ? 'active' : '' ]">
      <a class="modal-overlay" aria-label="Close" @click="showShare=false"></a>
      <div class="modal-container">
        <div class="modal-header">
          <button @click="showShare=false" class="btn btn-clear float-right"></button>
        </div>
        <div class="modal-body">
          <div class="content" style="font-size: 0.7rem !important;">
            <div class="mb-2">{{$t('scoutShareInstructions')}}</div>
            <a class="d-inline-block mb-2" :href="domain+'/?'+getValue(user, 'scoutId').toLowerCase()" target="_blank">{{domain}}/?{{getValue(user, 'scoutId').toLowerCase()}}</a><br>
            <button id="btn-copy-clipboard" class="btn btn-secondary btn-sm mt-2" @click="copyTextToClipboard($event, domain+'/?'+getValue(user, 'scoutId').toLowerCase())">{{$t('Copy')}}</button>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
    <div class="section-spacer"></div>
  </div>
</template>
<script>
import emitter from '@/lib/general/emitter'
const nestedProperty = require('nested-property')
import moment from 'moment'
import SiteNav from '@/components/SiteNav.vue'
import postAsync from '@/services/post-async'
import handleError from '@/services/handle-error'
import copyTextToClipboard from '@/lib/browser/copy-to-clipboard'
const initData = {
  transactions: [],
  totalPts: 0,
  loaded: false,
  firstname: '',
  lastname: '',
  mailing: false,
  street: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  disableRedeem: {
    GIFTCARD: true,
    CRYPTO: true
  }
}
const loadActivity = async (data, callback) => {
  const response = await postAsync({}, 'user/profile').catch((err) => {
    handleError(err)
    return false
  })
  data.totalPts = nestedProperty.get(response, 'data.points') || 0
  data.disableRedeem.GIFTCARD = data.totalPts < 150
  data.disableRedeem.CRYPTO = data.totalPts < 500
  data.transactions = nestedProperty.get(response, 'data.txns') || []
  // data.transactions.map(function(x) {
  //  data.totalPts += x.points && !x.flag ? x.points : 0
  // })
  data.mailing = nestedProperty.get(response, 'data.mailing')
  data.firstname = nestedProperty.get(response, 'data.firstname')
  data.lastname = nestedProperty.get(response, 'data.lastname')
  data.street = nestedProperty.get(response, 'data.street')
  data.city = nestedProperty.get(response, 'data.city')
  data.state = nestedProperty.get(response, 'data.state')
  data.zipcode = nestedProperty.get(response, 'data.zipcode')
  data.country = nestedProperty.get(response, 'data.country')
  if (typeof callback === 'function') {
    return callback(null)
  }
}

export default {
  name: 'ProfilePage',
  title: function() {
    return `Scout Action :: ${this.$t('profilePageTitle')}`
  },
  components: {
    SiteNav
  },
  props: {
    user: Object
  },
  data() {
    return {
      ...initData,
      msg: 'Profile Component',
      domain: process.env.VUE_APP_DOMAIN,
      thumbPath: process.env.VUE_APP_THUMB_PATH,
      accessToken: null,
      showShare: false,
      copyTextToClipboard: copyTextToClipboard.bind(this),
      disableAllRedeem: false
    }
  },
  beforeRouteEnter: function(to, from, next) {
    emitter.emit('loading', 'loading')
    loadActivity(initData, () => {
      initData.loaded = true
      emitter.emit('loading', '')
      next()
    })
  },
  created: function() {
    document.body.className = 'auth-zone'
  },
  mounted: function() {
    if (!this.loaded) {
      emitter.emit('loading', 'loading')
      loadActivity(this, () => {
        initData.loaded = true
        emitter.emit('loading', '')
      })
    } else {
      emitter.emit('loading', '')
    }
  },
  computed: {
    sortedTransactions: function() {
      const activities = {
        'PURCHASE': 'Compra de puntos',
        'MISSION_CREATED': 'Creación de misión',
        'BONUS_SIGNUP': 'Bono por registración',
        'SCOUT_REF': 'Usuario referido',
        'REDEEM': 'Canje de puntos',
        'ENTRY': 'Contribución a misión',
        'ENTRY_REF': 'Contribución referida',
        'GRANT': 'Puntos de regalo'
      }
      return this.transactions.slice().map(t => {
        t.dateTime = moment(t.createdAt).format('MMMM D, HH:mm')
        t.activity = activities[t.activity]
        return t
      })
    }
  },
  methods: {
    getValue: function(object, string) {
      return nestedProperty.get(object, string) || ''
    },
    async redeem(type) {
      emitter.emit('loading', 'loading')
      this.disableAllRedeem = true
      const payload = {
        type: type,
        lang: this.user.lang
      }
      postAsync(payload, 'points/redeem')
        .then(response => {
          alert(nestedProperty.get(response, 'data.message'))
          emitter.emit('loading', '')
        })
        .catch(error => {
          alert(nestedProperty.get(error, 'response.data.message'))
          emitter.emit('loading', '')
        })
    },
    async saveProp(prop) {
      emitter.emit('loading', 'loading')
      const payload = {
        prop: prop,
        value: this[prop]
      }
      await postAsync(payload, 'user/prefer').catch(error => {
        console.log(error)
      }).then(() => {
        emitter.emit('loading', '')
      })
    },
    async toggleMailing() {
      emitter.emit('loading', 'loading')
      const payload = {
        prop: 'mailing',
        value: this.mailing,
        email: this.user.email
      }
      await postAsync(payload, 'user/prefer').catch(error => {
        console.log(error)
      }).then(() => {
        emitter.emit('loading', '')
      })
    },
    localeValue: function(val) {
      const { current } = this.i18n
      return val.toLocaleString(current === 'es' ? 'es-AR' : 'en-US')
    },
    equivalentValue: function(points) {
      const { current } = this.i18n
      const fx = 20
      let val = points / fx
      return val.toLocaleString(current === 'es' ? 'es-AR' : 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    toggleShare: function() {
      this.showShare = !this.showShare
    }
  }
}
</script>
<style scoped>
.card-body span {
  font-size: 0.7rem;
  line-height: 0.95rem;
  margin-bottom: 0.3rem;
}
</style>