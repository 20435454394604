<template>
  <div>
    <WebNav />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
          <div class="section-spacer hide-sm"></div>
          <br class="show-sm">
          <h1 class="hide-sm"> {{$t('FAQ')}}</h1>
          <h2 class="show-sm"> {{$t('FAQ')}}</h2>
          <br>
          <!-- <img src="/board.svg" class="illustration"> -->
          <div v-html="$t('profileFAQs')"></div>
          <div class="section-spacer"></div>
          <button class="btn btn-sm my-2" style="" @click="$router.push({name: 'HomePage', params: {lang: lang}})">&larr; {{$t('backToHomePage')}}</button>
          <!-- footer -->
          <FooterNav page="AboutPage" />
          <!-- footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import WebNav from '@/components/WebNav.vue'
import FooterNav from '@/components/FooterNav.vue'
export default {
  name: 'AboutPage',
  title: function() {
    return `Scout Action :: ${this.$t('FAQ')}`
  },
  components: {
    WebNav,
    FooterNav
  },
  setup() {
    const route = useRoute()
    const lang = route.params ? route.params.lang : 'en'
    return { lang }
  },
  data: function() {
    return {
      username: '',
      userId: null
    }
  },
  beforeCreate: function() {
    document.body.className = 'public-zone'
  }
}
</script>
<style scoped>
div>>>p {
  font-family: Inter, sans-serif;
  font-size: 0.90rem !important;
  font-weight: 300 !important;
  color: #4C5059 !important;
}

ul {
  list-style-position: outside;
}

.title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #3B4351 !important;
  font-size: 2.7rem;
  line-height: 2.6rem;
  letter-spacing: -0.1rem;
}

@media screen and (max-width: 840px) {
  .title {
    font-size: 2.1rem;
    line-height: 2rem;
    letter-spacing: -0.1rem;
  }
}

h5 {
  margin-top: 75px;
}
</style>