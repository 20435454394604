export default function truncate(text = '', max = 130) {
  const previewRegex = /^[^.!?]+[.!?](?= [A-Z])?/
  let m
  if ((m = previewRegex.exec(text)) !== null) {
    let matched = m[0]
    if (matched.length > max) {
      matched = matched.substring(0, max - 3) + '...'
    }
    text = matched
  }
  let len = (text || '').length
  len = len > max ? max : len
  return {
    text,
    len
  }
}