<template>
  <div style="margin-top: 1rem; margin-bottom: 1rem;">
    <div v-if="isDevEnvironment">
      <select id="iplevelSelector" class="form-select mb-2" v-model="iplevel" @change="setIpLevel">
        <option value="20">EDITOR ({{user.username}})</option>
        <option value="130">USER ({{user.username}})</option>
      </select>
    </div>
    <header class="navbar">
      <section class="navbar-section" style="max-width: 130px;">
        <span>
          <a @click="$router.push({name: 'HomePage', params: {lang: lang}})" class="navbar-brand">Scout Action</a>
        </span>
      </section>
      <section class="navbar-section">
        <router-link :to="'/dash'" class="btn btn-primary btn-sm btn-hover mr-2">{{$t('Home')}}</router-link>
        <span v-if="user.userId">
          <router-link :to="'/profile'" class="btn btn-primary btn-sm btn-hover mr-2">{{$t('Account')}}</router-link>
          <button class="btn btn-primary btn-sm btn-hover" @click="$router.push({name: 'Logout', params: {lang: lang}})">
            {{$t('LogOut')}}
          </button>
        </span>
      </section>
    </header>
  </div>
</template>
<script>
import { isDevEnvironment } from '@/services/auth'
export default {
  name: 'SiteNav',
  props: {
    user: Object
  },
  data: function() {
    return {
      iplevel: localStorage.getItem('iplevel'),
      isDevEnvironment: isDevEnvironment()
    }
  },
  mounted: function() {
    this.lang = localStorage.getItem('lang') || 'en'
  },
  methods: {
    setIpLevel: function() {
      localStorage.setItem('iplevel', this.iplevel)
      location.reload()
    },
    hideMenu: function(id) {
      const el = document.getElementById(id)
      if (el) {
        el.blur()
      }
    },
    logout: function() {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
.dropdown .menu {
  min-width: 100px;
}

.input-search-container {
  width: 500px;
  margin-bottom: 4px;
}

.input-search-container .form-input {
  border: .05rem solid #eee;
}

@media screen and (max-width: 840px) {
  .dropdown-correct {
    margin-right: -5px;
  }

  .input-search-container {
    width: 120px;
    margin-left: 10px;
  }
}

.navbar-brand {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900 !important;
  color: #3B4351 !important;
  font-size: 0.9rem !important;
  letter-spacing: -0.04rem;
  text-decoration: none !important;
}

.navbar {
  padding: 0 0 0 5px;
  margin: 3px 0 5px 0;
  /*font-weight: 600;*/
}

@media screen and (max-width: 600px) {
  .navbar {
    padding: 0 0 0 5px;
    margin: 10px 0 10px 0;
    /*font-weight: 600;*/
  }
}

.navbar-header {
  padding: 6px 0 4px 0;
  height: 36px;
}

.has-icon-right {
  display: inline-block;
  bottom: -2px;
}

.form-input.input-sm {
  padding-bottom: 0;
}

.show-mobile {
  display: inline-block;
}

.hide-mobile {
  display: none;
}

@media (min-width: 840px) {
  .show-mobile {
    display: none;
  }

  .hide-mobile {
    display: inline-block;
    margin-right: .4rem;
  }
}
</style>