import postAsync from '@/services/post-async'
const nestedProperty = require('nested-property')

async function getUserPoints(userId) {
  const response = await postAsync({ userId: userId }, 'user/profile')
  return nestedProperty.get(response, 'data.points') || 0
}

export {
  getUserPoints
}