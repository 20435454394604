export default function copyTextToClipboard(evt, text, callback) {
  // const self = this
  // const targetId = evt.target.id
  const fallbackCopyTextToClipboard = function() {
    let textArea = document.createElement('textarea')
    textArea.value = text
    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    let successful
    try {
      successful = document.execCommand('copy')
      // let msg = successful ? 'successful' : 'unsuccessful'
      // console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      // console.error('Fallback: Oops, unable to copy', err)
    }
    document.body.removeChild(textArea)
    if (successful) {
      return callback(null, true)
    } else {
      return callback(null)
    }
  }
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
  }
  navigator.clipboard.writeText(text)
    .then(function() {
      // console.log('Copied')
      return callback(null, true)
    }, function() {
      // console.error('Could not copy')
      return callback(null)
    })
}