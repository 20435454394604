<template>
  <div class="columns">
    <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
      <SiteNav :user="user" />
      <div v-if="display404" class="empty">
        <div class="empty-icon">
          <i class="icon icon-emoji icon-2x"></i>
        </div>
        <p class="empty-title h5">Nothing to see here</p>
        <p class="empty-subtitle">Contact us if you want to report this.</p>
        <div class="empty-action">
          <a href="mailto:hi@scoutaction.com">hi@scoutaction.com</a>
        </div>
      </div>
      <div v-else class="card browse-card mb-2">
        <div class="card-body">
          <!-- <div style="background-color: #fff; padding: 1rem;"> -->
          <h4>{{ post.title }} <img v-if="post.teamAction===ITEM_STATUS_APPROVED" src='/img/check.svg' class='smallIcon'></h4>
          <p>Enviado para misión:
            <span v-if="isMissionOwner || isEditor">
              <router-link class="link" :to=" '/mission/' + mission.id ">{{ mission.title }}</router-link>
            </span>
            <span v-else>
              {{mission.title}}
            </span>
          </p>
          <p>Enviado por <strong>{{ post.user }}</strong> el {{ dt(post.created) }}</p>
          <br>
          <div class="field-area" v-if="post.etags && (isItemOwner || isEditor)">
            <div v-bind:key="tag" v-for="tag of post.etags" class="sa-tag">
              {{tag}}
            </div>
            <br>
          </div>
          <div class="field-area" v-if="post.location">
            <h6>Ubicación geográfica</h6>
            {{ post.location }}
          </div>
          <div class="field-area" v-if="post.description && (isItemOwner || isEditor)">
            <h6>Detalles</h6>
            <span v-html="post.description"></span>
          </div>
          <div class="field-area" v-if="post.itemOption && (isItemOwner || isEditor)">
            <h6>Etapa</h6>
            {{ itemOptions[post.itemOption] }}
          </div>
          <div class="field-area" v-if="itemLink">
            <h6>Web link del proyecto o startup</h6>
            <p><a v-bind:href="itemLink" target="_blank">{{itemLink}}</a></p>
          </div>
          <div class="field-area" v-if="post.firstname || post.lastname">
            <h6>Apellido, nombre del responsable</h6>
            {{ post.lastname }}, {{ post.firstname }}
          </div>
          <div class="field-area" v-if="personLink">
            <h6>Web link del responsable de proyecto o fundador</h6>
            <p><a v-bind:href="personLink" target="_blank">{{personLink}}</a></p>
          </div>
          <div v-if="isMissionOwner || isEditor" class="d-block p-relative">
            <a v-if="isEditor" class="btn btn-secondary btn-sm mr-2 mb-2 warning" v-on:click="delItem(post.id)">{{$t('Delete')}}</a>
            <span v-if="post.teamAction===ITEM_STATUS_APPROVED">
              <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="teamAction(post.id, ITEM_STATUS_PENDING)">{{$t('ActionRemoveFromApproved')}}</a>
            </span>
            <span v-else>
              <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="teamAction(post.id, ITEM_STATUS_APPROVED)">{{$t('ActionApprove')}}</a>
            </span>
            <span v-if="post.teamAction===ITEM_STATUS_VOIDED">
              <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="teamAction(post.id, ITEM_STATUS_PENDING)">{{$t('ActionRemoveFromRejected')}}</a>
            </span>
            <span v-else>
              <a class="btn btn-secondary btn-sm mr-2 mb-2" v-on:click="teamAction(post.id, ITEM_STATUS_VOIDED)">{{$t('ActionReject')}}</a>
            </span>
          </div>
          <!-- <p>
            <img src="/img/like.svg" class="smallIcon">{{ post.up }}&nbsp;&nbsp;<a class="link" v-on:click='vote("U")'>Like</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src="/img/dislike.svg" class="smallIcon">{{ post.down }}&nbsp;&nbsp;<a class="link" v-on:click='vote("D")'>Dislike</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src="/img/comments.svg" class="smallIcon">{{ post.comms }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src="/img/eye.svg" class="smallIcon">{{ post.hits }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span v-if="mission.sponsored">
              <span v-if="!post.accept">
                <span v-if="mission.sponsorId == userId">Pendiente (<a class="link" v-on:click='vote("A")'>aceptar</a>)</span>
                <span v-else>
                  Pendiente
                </span>
              </span>
              <span v-else>
                <img src='/img/check.svg' class='smallIcon'>Aceptado
              </span>
            </span>
          </p> -->
          <div class="image-container center" v-bind:key="img" v-for="img of post.media">
            <img v-lazy="imgPath + img" alt="">
          </div>
          <br>
          <!-- <div class="field-area">
            <h6>Comentarios</h6>
            <div v-if="!loggedIn">
              Puedes <router-link :to="{name: 'Login' }">ingresar</router-link> o <router-link :to="{name: 'Register'}">registrarte</router-link> para comentar.
            </div>
            <div v-bind:key="comm.id" v-for="comm of commentsList">
              <p><strong>{{comm.user}}:</strong> {{comm.txt}}</p>
            </div>
            <div v-if="loggedIn">
              <div class="col s12">
                <div class="row">
                  <div class="form-group">
                    <label class="form-label" for="comment">Agrega tu comentario aquí:</label>
                    <textarea id="comment" v-model="commentText"></textarea>
                  </div>
                  <div class="col s2">
                    <div style="margin-top: 1.3rem">
                      <a id="create-btn" class="btn btn-secondary" v-on:click='postComment()'>Enviar</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import config from '@/config'
import emitter from '@/lib/general/emitter'
import constants from '@/config/const'
import { funcs } from '@/services/datetime'
import { teamAction } from '@/services/team-action'
import validUrl from 'valid-url'
import SiteNav from '@/components/SiteNav.vue'
import postAsync from '@/services/post-async'
import handleError from '@/services/handle-error'
const nestedProperty = require('nested-property')
// import { deleteItem } from '@/services/delete-item.service'
const initData = {
  ...constants,
  post: {},
  mission: {},
  itemOptions: {},
  commentsList: [],
  itemLink: '',
  personLink: '',
  loaded: false,
  isMissionOwner: false,
  display404: false
}

const loadItem = async (data, itemId, callback) => {
  const teamId = localStorage.getItem('teamId')
  const payload = {
    itemId: itemId,
    teamId: teamId
  }
  const response = await postAsync(payload, 'getItem').catch((err) => {
    data.display404 = true
    return handleError(err)
  })
  if (!response.data || !response.data.post) {
    if (typeof callback === 'function') {
      return callback(new Error('Item not found'))
    }
    return false
  }
  data.post = response.data.post
  if (data.post && data.post.description) {
    data.post.description = data.post.description.replaceAll('\n', '<br><br>')
  }
  data.mission = response.data.mission
  data.isMissionOwner = data.mission.teamId === teamId
  if (data.mission && data.mission.itemOptions) {
    data.mission.itemOptions.map(function(x) {
      const re = new RegExp('([^\\[]+) \\[(.*)\\]')
      const found = x.match(re)
      if (found && found[1] && found[2]) {
        // console.log(found, found[1], found[2])
        data.itemOptions[found[2]] = found[1]
      }
    })
  }
  // data.commentsList = response.data.comments || []
  data.itemLink = validUrl.isWebUri(response.data.post.itemLink)
  data.itemLink = data.itemLink ? data.itemLink : ''
  data.personLink = validUrl.isWebUri(response.data.post.personLink)
  data.personLink = data.personLink ? data.personLink : ''
  if (typeof callback === 'function') {
    return callback(null)
  }
}

export default {
  components: {
    SiteNav
  },
  created() {
    document.body.className = 'auth-zone'
    this.id = this.$route.params.id
  },
  props: {
    user: Object
  },
  data: () => {
    return {
      ...initData,
      imgPath: process.env.VUE_APP_IMG_PATH
    }
  },
  beforeRouteEnter: function(to, from, next) {
    emitter.emit('loading', 'loading')
    loadItem(initData, to.params.id, () => {
      initData.loaded = true
      emitter.emit('loading', '')
      next()
    })
  },
  mounted: function() {
    if (!this.loaded) {
      loadItem(this, this.id, () => {
        initData.loaded = true
        emitter.emit('loading', '')
      })
    } else {
      emitter.emit('loading', '')
    }
  },
  computed: {
    isItemOwner: function() {
      const postId = nestedProperty.get(this, 'post.userId')
      const userId = nestedProperty.get(this, 'user.userId')
      const cond1 = postId !== undefined
      const cond2 = userId !== undefined
      const cond3 = postId === userId
      return cond1 && cond2 && cond3
    },
    isEditor: function() {
      return this.user.iplevel <= config.MAX_EDITOR_LEVEL
    }
  },
  methods: {
    getValue: function(object, string, defaultValue = '') {
      return nestedProperty.get(object, string, defaultValue)
    },
    async delItem(itemId) {
      const self = this
      if (!confirm('Are you sure?')) {
        return false
      }
      const payload = {
        itemId: itemId
      }
      await postAsync(payload, 'item/delete')
        .catch(err => {
          console.log(err)
          return false
        })
      self.$router.replace(`/mission/${self.mission.id}`)
    },
    async teamAction(itemId, action) {
      emitter.emit('loading', 'loading')
      await teamAction(this.mission.id, itemId, action)
        .catch(err => {
          return handleError(err)
        })
        .finally(() => {
          emitter.emit('loading', '')
        })
      if (action.startsWith(this.ITEM_STATUS_PENDING)) {
        delete this.post.teamAction
      } else {
        this.post.teamAction = action
      }
    },
    dt(ts) {
      return funcs.localdt(ts)
    },
    /*
    postComment() {
      var that = this
      var makeComment = function(id, v) {
        return comment(id, v)
          .catch((err) => {
            alert(err.message)
            return false
          })
          .then((data) => {
            //console.log(data)
            if (data && data.comment) {
              that.commentText = ''
              that.commentsList.push(data.comment)
            }
          })
      }
      makeComment(this.post.id, this.commentText)
    },
    vote(v) {
      var that = this
      if (!this.$cognito.getUserId()) {
        alert('Thanks for your vote! But you need to log in first...')
        return false
      }
      if (v === 'A') {
        var r = confirm('Are you sure? It cannot be undone...')
        if (!r) {
          return false
        }
      }
      //console.log('Voted:', v)
      var doit = function(id, v) {
        return vote(id, v)
          .catch((err) => {
            console.log(err);
          })
          .then((data) => {
            that.post.up = data.up
            that.post.down = data.down
            if (data.accept) {
              that.post.accept = data.accept
            }
          })
      }
      doit(this.post.id, v);
    }*/
  }
}
</script>
<style>
.field-area {
  margin-bottom: 2rem;
}
</style>
<style scoped>
.image-container {
  margin-top: 20px;
  /*background-color: white;
    padding: 20px;
    border-radius: 3px;*/
}

.image-container img {
  max-width: 100%;
}

textarea {
  max-width: 500px;
}
</style>