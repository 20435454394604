export default [{
    name: 'internal',
    title: 'Internal mission',
    description: 'Participants only need to share a link to participate.',
    limits: 'None',
    lang: 'es',
    image: 'scouts.svg',
    minPoints: 1,
    authLevel: 20
  },
  {
    name: 'talent-es.v1',
    title: 'Búsqueda de talento',
    description: 'Los participantes envían su Curriculum Vitae en formato PDF con sus datos de contacto.',
    limits: 'Solo un archivo en formato PDF por participación',
    lang: 'es',
    image: 'talent.svg',
    minPoints: 20,
    authLevel: 20
  },
  {
    name: 'receipt-es.v1',
    title: 'Recibos de compra',
    description: 'Los participantes contribuyen fotos de tickets de compras sin ninguna información adicional.',
    limits: 'Solo una imagen de ticket por participación',
    lang: 'es',
    image: 'receipt.svg',
    minPoints: 3,
    authLevel: 130
  },
  {
    name: 'photo-es.v1',
    title: 'Concurso de fotos',
    description: 'Los participantes contribuyen fotos con características que vos pidas incluyendo un título y la ubicación donde la foto fue tomada.',
    limits: 'Solo una imagen por participación',
    lang: 'es',
    image: 'photo.svg',
    minPoints: 5,
    authLevel: 130
  },
  {
    name: 'report-es.v1',
    title: 'Reporte de problemas',
    description: 'Los participantes reportan problemas u otras situaciones con las que se encuentran de acuerdo con el objetivo de la misión. Cada reporte incluye una descripción y una o más imágenes.',
    limits: 'Hasta cinco imágenes por participación',
    lang: 'es',
    image: 'report.svg',
    minPoints: 20,
    authLevel: 130
  },
  {
    name: 'project-es.v1',
    title: 'Ideas y proyectos',
    description: 'Los participantes completan un formulario acerca de una idea de proyecto o proyecto en el cual estan trabajando, incluyendo información sobre la idea o proyecto y una persona responsable.',
    limits: 'Hasta cinco imágenes por participación',
    lang: 'es',
    image: 'project.svg',
    minPoints: 100,
    authLevel: 20
  },
  {
    name: 'startup-es.v1',
    title: 'Startups',
    description: 'Los participantes completan un formulario acerca de un startup en el cual estan trabajando, incluyendo información sobre el startup y una persona responsable.',
    limits: 'Hasta cinco imágenes por participación',
    lang: 'es',
    image: 'startup.svg',
    minPoints: 100,
    authLevel: 20
  }
]