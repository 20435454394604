export default function copyTextToClipboard(ctx, evt, text) {
  console.log(ctx, evt, text)
  const targetId = evt.target.id
  const fallbackCopyTextToClipboard = function() {
    var textArea = document.createElement('textarea')
    textArea.value = text
    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      var successful = document.execCommand('copy')
      var msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }
    document.body.removeChild(textArea)
  }
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
    document.getElementById(targetId).innerHTML = ctx.$t('Copied')
    setTimeout(() => {
      document.getElementById(targetId).innerHTML = ctx.$t('Copy')
    }, 3000)
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}