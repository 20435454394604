import * as axios from 'axios'
import { getUser, getJwtToken } from '@/services/auth'

function newPost(payload) {
  return new Promise(function(fulfilled, rejected) {
    axios.defaults.headers.common['Authorization'] = getJwtToken()
    const user = getUser()
    payload.user = user.username
    payload.userId = user.userId
    return axios({
        method: 'post',
        url: process.env.VUE_APP_EXEC_API + '/saveItem',
        data: payload
      })
      .then(x => {
        fulfilled(x)
      })
      .catch((err) => {
        rejected(err)
      })
  })
}

export {
  newPost
}