import { postService } from '@/services/post'
import { getTeamId } from '@/services/auth'
const teamAction = async (missionId, itemId, action, notes = '') => {
  const teamId = getTeamId()
  const params = {
    missionId: missionId,
    teamId: teamId,
    itemId: itemId,
    teamAction: action,
    notes: notes
  }
  return await postService(params, 'teamAction')
}
export { teamAction }