// import App from '@/App'
import Layout from '@/views/LayoutPage'
import Home from '@/views/HomePage'
import Dash from '@/views/DashPage'
import Login from '@/views/LoginPage'
import QuickConfirm from '@/views/QuickConfirmPage'
import About from '@/views/AboutPage'
import Scouts from '@/views/ScoutsPage'
import Profile from '@/views/ProfilePage'
import Help from '@/views/HelpPage'
import NewItem from '@/views/NewItem'
import Register from '@/views/RegisterPage'
import SuccessItem from '@/views/SuccessItemPage'
import SuccessMission from '@/views/SuccessMissionPage'
import SuccessReg from '@/views/SuccessRegPage'
import Single from '@/views/SinglePage'
import Mission from '@/views/MissionPage'
import NewMission from '@/views/NewMission'
import MissionSelector from '@/views/MissionSelector'
import ResetPage from '@/views/ResetPage'
import ErrorPage from '@/views/ErrorPage'
import Control from '@/views/ControlPage'
import { getUser, logoutUser } from '@/services/auth'
// import postAsync from '@/services/post-async'
// import handleError from '@/services/handle-error'
// import emitter from '@/lib/general/emitter'

function requireAuth(to, from, next) {
  const user = getUser()
  if (user && user.userId) {
    return next()
  }
  next({
    path: `/${(to.params.lang && to.params.lang ? `${to.params.lang}/` : '')}login`,
    query: { redirect: to.fullPath }
  })
}

function logout(to, from, next) {
  // const user = getUser()
  logoutUser()
  /*
  if (user) {
    await postAsync({ username: user.username }, 'user/logout').catch(error => {
      handleError(error)
    })
  }
  */
  next('/' + (to.params.lang && to.params.lang ? to.params.lang : ''))
}

/*
async function demo(to, from, next) {
  emitter.emit('loading', 'loading')
  const payloadLogin = {
    username: 'researcher',
    password: 'Researcher1!'
  }
  const loginResponse = await postAsync(payloadLogin, 'user/login').catch(error => {
    emitter.emit('loading', '')
    return handleError(error)
  })
  emitter.emit('login', loginResponse)
  next('/dash')
}
const nowhere = () => new Promise(() => {})
*/

const routes = [{
  path: '/:lang(en|es)?',
  name: 'LayoutPage',
  component: Layout,
  children: [
    { path: '', name: 'HomePage', component: Home },
    { path: 'dash', name: 'DashPage', component: Dash, beforeEnter: requireAuth, props: true },
    { path: 'p/:id', component: Single, beforeEnter: requireAuth, props: true },
    { path: 'mission/:missionId', component: Mission, beforeEnter: requireAuth, props: true },
    { path: 'sm', name: 'MissionSelector', component: MissionSelector, beforeEnter: requireAuth, props: true },
    { path: 'nm/:formId', name: 'NewMission', component: NewMission, beforeEnter: requireAuth, props: true },
    { path: 'em/:missionId', component: NewMission, beforeEnter: requireAuth, props: true },
    { path: 'help', name: 'HelpPage', component: Help },
    { path: 'reset', name: 'ResetPage', component: ResetPage },
    { path: 'reset/:resetEmail/:resetCodeProp', component: ResetPage, props: true },
    { path: 'login', name: 'LoginPage', component: Login },
    { path: 'login/:confirmEmail/:confirmUsername/:confirmCode', component: Login, props: true },
    // { path: 'demo/202202z6', component: nowhere, beforeEnter: demo },
    { path: 'scouts', name: 'ScoutsPage', component: Scouts },
    { path: 'about', name: 'AboutPage', component: About },
    { path: 'successr', name: 'SuccessRegPage', component: SuccessReg },
    { path: 'success-item', name: 'SuccessItemPage', component: SuccessItem },
    { path: 'success-mission', name: 'SuccessMissionPage', component: SuccessMission },
    { path: 'profile', name: 'ProfilePage', component: Profile, beforeEnter: requireAuth, props: true },
    { path: 'n/:id', name: 'NewItem', component: NewItem, beforeEnter: requireAuth, props: true },
    { path: 'gables', name: 'Control', component: Control, beforeEnter: requireAuth, props: true },
    { path: 'register', name: 'RegisterPage', component: Register },
    { path: 'error404', name: 'ErrorPage', component: ErrorPage, props: { code: '404', message: 'Not found' } },
    { path: 'logout', name: 'Logout', beforeEnter: logout },
    {
      path: 'confirm/:confirmEmail/:confirmUsername/:confirmCode',
      redirect: to => ({ path: '/' + to.params.lang + '/login/' + to.params.confirmEmail + '/' + to.params.confirmUsername + '/' + to.params.confirmCode })
    },
    { path: 'qconfirm/:username/:code/:firstPass/:missionId', component: QuickConfirm, props: true },
    // { path: '.*', name: 'ErrorPage', component: ErrorPage, props: { code: '404', message: 'Not found' } }
  ]
}]

export default routes