// import { createApp, computed } from 'vue'
// import routes from '@/router/routes.js'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n-lite'
import App from './App.vue'
import router from '@/router'
import VueGtag from 'vue-gtag-next'
import titleMixin from './services/titleMixin'
import VueLazyLoad from 'vue3-lazyload'
import messages from './lang'
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})
const app = createApp(App)
app.use(i18n)
// app.provide('userIpLevel', computed(() => 999))
app.mixin(titleMixin)
app.use(VueLazyLoad, {
  error: '/img/spinner.gif',
  loading: '/img/spinner.gif',
  lifecycle: {
    loading: () => {},
    error: (el) => {
      // console.log('error', el, el.src)
      setTimeout(() => {
        el.src = '/empty.png'
      }, '1300')
    },
    loaded: () => {}
  }
})
app.use(VueGtag, {
  property: { id: 'UA-55345670-10' },
  // useDebugger: true,
  isEnabled: false
})
app.config.globalProperties.i18n = i18n
app.use(router, {
  i18n
})
app.mount('#app')
// export { app }