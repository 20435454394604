<template>
  <div class="footer">
    <div class="divider"></div>
    <div class="section-spacer"></div>
    <div class="columns">
      <div class="column col-lg-12 col-6">
        <div class="col-container">
          <p class="footerp"><strong>{{$t('MailingAddress')}}</strong></p>
          <p class="footerp">Scout Action, Inc.<br>340 S Lemon Ave #1813<br>Walnut, California 91789<br>United States</p>
          <br>
        </div>
      </div>
      <div class="column col-lg-12 col-3">
        <div class="col-container">
          <p class="footerp"><strong>{{$t('Company')}}</strong></p>
          <!-- <p v-if="page!=='AboutPage'" class="footerp">
            <router-link :to="({name: 'AboutPage', params: {lang: lang}})">{{$t('About')}}</router-link>
          </p>
          <p v-else class="footerp">
            <router-link :to="({name: 'HomePage', params: {lang: lang}})">{{$t('Home')}}</router-link>
          </p> -->
          <!-- <p class="footerp"><a href="https://scoutaction.substack.com/" target="_blank">Blog</a></p> -->
          <p class="footerp"><a href="mailto:hi@scoutaction.com">Email</a></p>
          <p class="footerp"><a href="https://twitter.com/ScoutAction" target="_blank">Twitter</a></p>
          <br>
        </div>
      </div>
      <div class="column col-lg-12 col-3">
        <div class="col-container">
          <p class="footerp"><strong>Scouts</strong></p>
          <p v-if="page!=='HelpPage'" class="footerp">
            <router-link :to="({name: 'HelpPage', params: {lang: lang}})">{{$t('Help')}}</router-link>
          </p>
          <p class="footerp">
            <router-link :to="({name: 'LoginPage', params: {lang: lang}})">{{$t('LogIn')}}</router-link>
          </p>
          <p class="footerp">
            <router-link :to="({name: 'RegisterPage', params: {lang: lang}})">{{$t('Register')}}</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="section-spacer"></div>
    <p class="footerp">© 2022 Scout Action, Inc. Illustrations by <a href="https://storyset.com" target="_blank">Storyset</a>.</p>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
export default {
  name: 'FooterNav',
  props: {
    page: {
      type: String
    }
  },
  setup() {
    const route = useRoute()
    const lang = route.params ? route.params.lang : 'en'
    return { lang }
  },
  data: function() {
    return {}
  },
  methods: {}
}
</script>
<style scoped>
.footerp {
  font-size: 0.75rem !important;
  line-height: 0.9rem;
  margin: 0 0 0.3rem;
}

@media only screen and (max-width: 600px) {
  .footerp {
    font-size: 0.85rem;
    line-height: 0.95rem;
  }
}
</style>