<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column col-md-8 col-lg-4 col-xl-3 col-2 p-centered">
          <div class="section-spacer"></div>
          <div v-if="error" class="text-center">
            <div class="label label-error mb-2">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from '@/lib/general/emitter'
const nestedProperty = require('nested-property')
import postAsync from '@/services/post-async'
import { logoutUser } from '@/services/auth'
export default {
  name: 'QuickConfirmPage',
  title: function() {
    return `Scout Action :: ${this.$t('LogIn')}`
  },
  props: {
    username: String,
    code: String,
    firstPass: String,
    missionId: String
  },
  data: function() {
    return {
      currentLocale: 'en',
      error: ''
    }
  },
  beforeCreate: function() {
    document.body.className = 'public-zone'
  },
  mounted: async function() {
    this.currentLocale = localStorage.getItem('lang') || nestedProperty.get(this, '$route.params.lang')
    if (this.username && this.code) {
      await this.confirmFn(this.username, this.code)
    }
  },
  methods: {
    async confirmFn(username, code) {
      const self = this
      emitter.emit('loading', 'loading')
      logoutUser()
      const payload = {
        username: username,
        code: code,
        firstPass: this.firstPass,
        lang: this.currentLocale || 'en'
      }
      let response
      try {
        response = await postAsync(payload, 'user/quick-confirm')
        emitter.emit('login', response)
        let lang
        if (localStorage.getItem('lang')) {
          lang = localStorage.getItem('lang')
        } else {
          lang = response.data.lang
          localStorage.setItem('lang', lang)
        }
        const scoutRefId = localStorage.getItem('scoutRefId') || ''
        const payloadCheckin = {
          username: response.data.username,
          scoutRefId: scoutRefId,
          props: {
            email: response.data.email,
            lang: self.currentLocale,
            mailing: nestedProperty.get(response, 'data.mailing')
          }
        }
        const checkInResponse = await postAsync(payloadCheckin, 'checkin')
        emitter.emit('checkin', checkInResponse)
        emitter.emit('loading', '')
        self.$router.replace(self.missionId ? `/n/${self.missionId}` : '/dash')
      } catch (error) {
        const code = nestedProperty.get(error, 'response.data.code')
        if (code) {
          if (code === 'CodeMismatchException') {
            self.error = self.$t('ConfirmationError')
          } else if (code === 'NotAuthorizedException') {
            self.error = self.$t('AlreadyConfirmed')
          } else {
            self.error = `${self.$t('SomethingWentWrong')} ${self.$t('ContactToReport')}`
          }
        } else {
          let msg = nestedProperty.get(error, 'response.data.message')
          self.error = msg ? msg : self.$t('SomethingWentWrong')
        }
        emitter.emit('loading', '')
      }
    }
  }
}
</script>