module.exports = function(str, maxLen = 25, separator = ',') {
  if (!str) {
    return []
  }
  let labels = []
  return str.split(separator)
    .map(function(x) {
      return x ? x.trim().substring(0, maxLen) : ''
    })
    .filter(function(x) {
      if (x === '') {
        return false
      }
      if (labels.indexOf(x) !== -1) {
        return false
      }
      labels.push(x)
      return true
    })
}