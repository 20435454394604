import jwt_decode from 'jwt-decode'
// import * as axios from 'axios'
// const axios = require('axios').default
// const nestedProperty = require('nested-property')

export function getJwtToken() {
  return localStorage.getItem('jwt')
}

export function setJwtToken(token) {
  localStorage.setItem('jwt', token)
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken')
}

export function setRefreshToken(token) {
  localStorage.setItem('refreshToken', token)
}

export function isDevEnvironment() {
  return window.location.hostname !== 'scoutaction.com'
}

export function getIpLevel() {
  const token = getJwtToken()
  const decode = jwt_decode(token)
  const sessionIpLevel = Number(decode['custom:iplevel']) || 999
  const storageIpLevel = Number(localStorage.getItem('iplevel')) || sessionIpLevel
  if (isDevEnvironment()) {
    console.log('role:', sessionIpLevel, 'navigating as:', storageIpLevel)
  }
  return isDevEnvironment() ? storageIpLevel : sessionIpLevel
}

export function getScoutId() {
  return localStorage.getItem('scoutId')
}

export function getTeamId() {
  return localStorage.getItem('teamId')
}

export function setScoutId(scoutId) {
  localStorage.setItem('scoutId', scoutId)
}

export function setTeamId(teamId) {
  localStorage.setItem('teamId', teamId)
}

export function tokenIsValid() {
  const token = getJwtToken()
  try {
    const decode = jwt_decode(token)
    return (decode.exp * 1000) >= (new Date().getTime() - 60000)
  } catch (error) {
    return false
  }
}
/*
export async function refreshToken() {
  // console.log('asked refresh')
  const token = getRefreshToken()
  const params = {
    method: 'post',
    url: process.env.VUE_APP_EXEC_API + '/user/refresh',
    data: { token: token }
  }
  const response = await axios(params)
    .catch(err => {
      console.log(err)
      return
    })
  // console.log(response)
  setJwtToken(nestedProperty.get(response, 'data.idToken'))
}
*/
export function getUser() {
  const token = getJwtToken()
  if (!token) {
    // console.log('no token!')
    return undefined
  }
  if (!tokenIsValid()) {
    logoutUser()
    return undefined
  }
  const decode = jwt_decode(token)
  const user = {
    username: decode['cognito:username'],
    iplevel: getIpLevel(), // parseInt(decode['custom:iplevel']),
    lang: decode['custom:lang'],
    mailing: decode['custom:mailing'],
    email: decode['email'],
    userId: decode['sub'],
    scoutId: getScoutId(),
    teamId: getTeamId()
  }
  return user
}

export function logoutUser() {
  setJwtToken('')
}