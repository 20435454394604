<template>
  <div class="columns">
    <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth">
      <SiteNav :user="user" />
      <div class="content-area">
        <h5>{{$t('NewMissionSelectMissionTypeTitle')}}</h5>
        <br>
        <div v-bind:key="type.name" v-for="type in sortedMissionTypes">
          <img :src="'/img/' + type.image" class="illustration">
          <div style="margin-bottom: 2rem;">
            <h6>{{type.title}}</h6>
            <p>
              {{type.description}}<br>
              {{$t('newMissionLimits')}}: {{type.limits}}<br>
              {{$t('newMissionMinPoints')}}: {{type.minPoints}}<br>
              {{$t('Lang')}}: {{languages[type.lang]}}<br>
            </p>
            <router-link class="btn btn-primary btn-sm" :to=" '/nm/' + type.name ">{{$t('NewMissionChooseAndEdit')}} &rarr;</router-link>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div id="bottom-space"></div>
    </div>
  </div>
</template>
<script>
import missionTypes from '@/lib/mission/mission-types'
import SiteNav from '@/components/SiteNav.vue'
export default {
  name: 'MissionSelector',
  components: {
    SiteNav
  },
  props: {
    user: Object
  },
  data: function() {
    return {
      languages: {
        'es': 'Español',
        'en': 'English'
      },
      missionTypes: missionTypes
    }
  },
  computed: {
    sortedMissionTypes: function() {
      return this.missionTypes.slice().filter(m => m.authLevel >= this.user.iplevel)
    }
  }
}
</script>
<style scoped>
.illustration {
  float: right;
  width: 130px;
  margin-left: 20px;
}
</style>